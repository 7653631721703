import React from 'react'
import { Link } from 'react-router-dom'

const Tempo11 = () => {
function handleCLickLink (){
	window.scrollTo(0,0);
}

	return (
		<>
			
			<div className="container py-5 my-4">
				

				<div className="row justify-content-center pt-3">
					
					<div className="col-lg-10 text-center">
						

						<h2 className="custom-highlight-text-1 d-inline-block line-height-5 text-4 positive-ls-3 font-weight-medium text-color-primary mb-2 appear-animation animated fadeInUpShorter appear-animation-visible"
							data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250"
							style={{ animationDelay: '250ms' }}>THE BLOG</h2>
						<h3 className="text-9 line-height-3 text-transform-none font-weight-semibold mb-4 appear-animation animated fadeInUpShorter appear-animation-visible"
							data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500"
							style={{ animationDelay: '500ms' }}>Our Recent News
						</h3>
						<p className="text-3-5 pb-3 mb-4 appear-animation animated fadeInUpShorter appear-animation-visible"
							data-appear-animation="fadeInUpShorter" data-appear-animation-delay="750"
							style={{ animationDelay: '750ms' }}>Discover the latest happenings in the world of AI and
							development through our recent news blog. Stay informed about cutting-edge technologies,
							industry insights, and innovative solutions that are shaping the future. </p>



					</div>
				</div>

				<div className="row row-gutter-sm justify-content-center mb-4 appear-animation animated fadeInUpShorter appear-animation-visible"
					data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000"
					style={{ animationDelay: '1000ms' }}>
					
					<div className="col-sm-9 col-md-6 col-lg-4 mb-4 mb-lg-0">
						

						<Link to="blog" onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none"
							data-cursor-effect-hover="plus">
							<div className="card border-0 box-shadow-4">
								
								<div className="card-img-top position-relative overlay">
									
									
									<div className="position-absolute bottom-10 right-0 d-flex justify-content-end w-100 py-3 px-4 z-index-3">
										
										<span className="custom-date-style-1 text-center bg-primary text-color-light font-weight-semibold text-5-5 line-height-2 px-3 py-2">
											<span className="position-relative z-index-2">
												18
												<span className="d-block custom-font-size-1 positive-ls-2 px-1">SEP</span>
											</span>
										</span>

									</div>

									<img src="assets/img/demos/business-consulting-3/blog/blog-1.png" className="img-fluid" alt="Lorem Ipsum Dolor" />

								</div>

								<div className="card-body p-4">
									<span className="d-block text-color-grey font-weight-semibold positive-ls-2 text-2">Artificial
										Intelligence</span>
									<h4 className="font-weight-semibold text-5 text-color-hover-primary mb-2">Exploring
										Quantum Computing and AI in the Digital Age.</h4>

									<span className="custom-view-more d-inline-flex font-weight-medium text-color-primary">
										View More
										<img width="27" height="27" src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt="" data-icon=""
											data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary ms-2'}"
											style={{ width: '27px', opacity: '1' }} className="svg-fill-color-primary ms-2" />
									</span>
								</div>
							</div>

						</Link>
					</div>

					<div className="col-sm-9 col-md-6 col-lg-4 mb-4 mb-lg-0">

						<Link to="blog" onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none" data-cursor-effect-hover="plus">
							<div className="card border-0 box-shadow-4">

								<div className="card-img-top position-relative overlay">

									<div className="position-absolute bottom-10 right-0 d-flex justify-content-end w-100 py-3 px-4 z-index-3">
										<span className="custom-date-style-1 text-center bg-primary text-color-light font-weight-semibold text-5-5 line-height-2 px-3 py-2">
											<span className="position-relative z-index-2">
												14
												<span className="d-block custom-font-size-1 positive-ls-2 px-1">SEP</span>
											</span>
										</span>


									</div>

									<img src="assets/img/demos/business-consulting-3/blog/blog-2.png" className="img-fluid" alt="Lorem Ipsum Dolor" />

								</div>

								<div className="card-body p-4">
									<span className="d-block text-color-grey font-weight-semibold positive-ls-2 text-2">Development</span>
									<h4 className="font-weight-semibold text-5 text-color-hover-primary mb-2">Exploring
										Innovative Solutions and Trends in IT Development.</h4>
									<span className="custom-view-more d-inline-flex font-weight-medium text-color-primary">
										View More

										<img width="27" height="27" src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt="" data-icon=""
											data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary ms-2'}"
											style={{ width: '27px', opacity: '1' }} className="svg-fill-color-primary ms-2" />


									</span>
								</div>

							</div>

						</Link>

					</div>

					<div className="col-sm-9 col-md-6 col-lg-4">
						<Link to="blog"  onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none" data-cursor-effect-hover="plus">
							<div className="card border-0 box-shadow-4">

								<div className="card-img-top position-relative overlay">
									<div className="position-absolute bottom-10 right-0 d-flex justify-content-end w-100 py-3 px-4 z-index-3">
										<span className="custom-date-style-1 text-center bg-primary text-color-light font-weight-semibold text-5-5 line-height-2 px-3 py-2">
											<span className="position-relative z-index-2">
												12
												<span className="d-block custom-font-size-1 positive-ls-2 px-1">SEP</span>
											</span>
										</span>


									</div>

									<img src="assets/img/demos/business-consulting-3/blog/blog-3.png" className="img-fluid" alt="Lorem Ipsum Dolor" />


								</div>

								<div className="card-body p-4">
									<span className="d-block text-color-grey font-weight-semibold positive-ls-2 text-2">Automantion</span>
									<h4 className="font-weight-semibold text-5 text-color-hover-primary mb-2"> Exploring
										Latest in Automation and Technological Innovations.</h4>
									<span className="custom-view-more d-inline-flex font-weight-medium text-color-primary">
										View More
										<img width="27" height="27" src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt="" data-icon=""
											data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary ms-2'}"
											style={{ width: '27px', opacity: '1' }} className="svg-fill-color-primary ms-2" />


									</span>

								</div>
							</div>

						</Link>

					</div>
				</div>

			</div>


		</>
	)
}

export default Tempo11