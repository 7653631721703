import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import './OwlTempo8css.css';


const OwlTempo8 = () => {
function handleCLickLink (){
    window.scrollTo(0,0);
}

    return (
        <>
            <section className="section section-height-4 section-with-shape-divider bg-color-grey-scale-1 border-0 pb-5 m-0">
                <div className="shape-divider" style={{ height: '123px' }}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 123" preserveAspectRatio="xMinYMin">
                        <polygon fill="#F3F3F3" points="0,90 221,60 563,88 931,35 1408,93 1920,41 1920,-1 0,-1 "></polygon>
                        <polygon fill="#FFFFFF" points="0,75 219,44 563,72 930,19 1408,77 1920,25 1920,-1 0,-1 "></polygon>
                    </svg>
                </div>

                <div className="container mt-4">

                    <div className="row justify-content-center">

                        <div className="col-lg-11 col-xl-10 text-center">
                            <h2 className="custom-highlight-text-1 d-inline-block line-height-5 text-4 positive-ls-3 font-weight-medium text-color-primary mb-2 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250" style={{ animationDelay: '250ms' }}>THE PORTFOLIO
                            </h2>
                            <h3 className="text-9 line-height-3 text-transform-none font-weight-semibold mb-4 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500" style={{ animationDelay: '500ms' }}>Some of
                                Projects</h3>
                            <p className="text-3-5 pb-3 mb-4 appear-animation animated fadeInUpShorter appear-animation-visible"
                                data-appear-animation="fadeInUpShorter" data-appear-animation-delay="750"
                                style={{ animationDelay: '750ms' }}>Create a mobile &amp; Website application tailored to meet
                                specific business needs, improving customer engagement and accessibility.</p>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col appear-animation animated fadeInUpShorter appear-animation-visible"
                            data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000"
                            style={{ animationDelay: '1000ms' }}>




                            <OwlCarousel className="owl-carousel owl-theme custom-dots-style-1 mb-0 owl-loaded owl-drag owl-carousel-init"
                               
                            //    data-plugin-options="{'responsive': {'576': {'items': 1}, '768': {'items': 3}, '992': {'items': 3}, '1200': {'items': 5}}, 'margin': 20, 'loop': false, 'nav': false, 'dots': true}" 
                            
                             style={{ height: 'auto' }}
                                items={5}
                                loop={false} margin={10} nav dots={true}
                                responsive={{
                                    0: { items: 1, dots:true, center:true, margin:5},
                                    576: { items: 2  ,dots:true, center:true, margin:5},
                                    768: { items: 3 , margin:10},
                                    992: { items: 3 },
                                    1200: { items: 5 },
                                
                                  }}
                                
                                >

                                {/* <div className="owl-stage-outer"> */}

                                {/* <div className="owl-stage" style={{transform: 'translate3d(0px, 0px, 0px)', transition: 'all 0s ease 0s', width: '1614px'}}> */}


                                <div className="slide-position item" style={{ width: '249px', marginRight: '20px' }}>

                                    <div  >
                                        <Link to="portfolio#john" onClick={handleCLickLink} className=" custom-link-hover-effects text-decoration-none" data-cursor-effect-hover="plus">

                                            <div className="card border-0">

                                                <div className="card-img-top position-relative overlay">

                                                    <div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">

                                                        <div className="custom-crooked-line">
                                                            <img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />
                                                        </div>
                                               </div>
                                                    <img src="assets/img/demos/business-consulting-3/team/team-1.png" className="img-fluid" alt="Arhan G&amp;D" />
                                                </div>

                                                <div className="card-body">
                                                    <h4 className="text-color-hover-primary text-4 mb-0">Arhan G&amp;D</h4>
                                                    <p className="text-3 mb-0">Digital Shopping</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    </div>



                                <div className="slide-position item" style={{ width: '249px', marginRight: '20px' }}>
                                    <div>
                                        <Link to="portfolio#robert" onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none" data-cursor-effect-hover="plus">
                                            <div className="card border-0">
                                                <div className="card-img-top position-relative overlay">
                                                    <div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
                                                        <div className="custom-crooked-line">
                                                            <img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />
                                                        </div>
                                                    </div>


                                                    <img src="assets/img/demos/business-consulting-3/team/team-2.png" className="img-fluid" alt="E-Muallim" />
                                                </div>
                                                <div className="card-body">
                                                    <h4 className="text-color-hover-primary text-4 mb-0">E-Muallim</h4>
                                                    <p className="text-3 mb-0">study material</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>



                                <div className="slide-position item" style={{ width: '249px', marginRight: '20px' }}>

                                    <div>
                                        <Link to="portfolio#jessica" onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none" data-cursor-effect-hover="plus">

                                            <div className="card border-0">

                                                <div className="card-img-top position-relative overlay">

                                                    <div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">

                                                        <div className="custom-crooked-line">
                                                            <img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />
                                                        </div>
                                                    </div>
                                                    <img src="assets/img/demos/business-consulting-3/team/team-3.png" className="img-fluid" alt="Nippen" />
                                                </div>

                                                <div className="card-body">
                                                    <h4 className="text-color-hover-primary text-4 mb-0 ">Nippen</h4>
                                                    <p className="text-3 mb-0">Electrical Instrument Co.</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div></div>



                                <div className="slide-position item" style={{ width: '249px', marginRight: '20px' }}>

                                    <div>
                                        <Link to="portfolio#monica"  onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none" data-cursor-effect-hover="plus">

                                            <div className="card border-0">

                                                <div className="card-img-top position-relative overlay">

                                                    <div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">

                                                        <div className="custom-crooked-line">
                                                            <img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />
                                                        </div>
                                                    </div>
                                                    <img src="assets/img/demos/business-consulting-3/team/team-4.png" className="img-fluid" alt="La-Ribaa" />
                                                </div>

                                                <div className="card-body">
                                                    <h4 className="text-color-hover-primary text-4 mb-0">La-Ribaa</h4>
                                                    <p className="text-3 mb-0">Banking Solutions</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div></div>

                                <div className="slide-position item" style={{ width: '249px', marginRight: '20px' }}>

                                    <div>
                                        <Link to="portfolio#chris" onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none" data-cursor-effect-hover="plus">


                                            <div className="card border-0">

                                                <div className="card-img-top position-relative overlay">

                                                    <div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">

                                                        <div className="custom-crooked-line">
                                                            <img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />
                                                        </div>
                                                    </div>
                                                    <img src="assets/img/demos/business-consulting-3/team/team-5.png" className="img-fluid" alt="Campus Connekt" />
                                                </div>

                                                <div className="card-body">
                                                    <h4 className="text-color-hover-primary text-4 mb-0">Campus Connekt</h4>
                                                    <p className="text-3 mb-0">School Management </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div></div>


                                <div className="slide-position item" style={{ width: '249px', marginRight: '20px' }}>

                                    <div>
                                        <Link to="portfolio#john"  onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none" data-cursor-effect-hover="plus">

                                            <div className="card border-0">

                                                <div className="card-img-top position-relative overlay">

                                                    <div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">

                                                        <div className="custom-crooked-line">
                                                            <img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" style={{ width: '154px' }} />
                                                        </div>
                                                    </div>
                                                    <img src="assets/img/demos/business-consulting-3/team/team-6.png" className="img-fluid" alt="Hajj Committee of India" />
                                                </div>

                                                <div className="card-body">
                                                    <h4 className="text-color-hover-primary text-4 mb-0">Hajj Committee of India
                                                    </h4>
                                                    <p className="text-3 mb-0">Automation VISA process</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div></div>








                                {/* </div> */}
                                {/* </div> */}





                            </OwlCarousel>








                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OwlTempo8