import React from 'react'
import { Link } from 'react-router-dom'

const Tempo4 = () => {

function handleCLickLink (){
    window.scrollTo(0,0)
}


    return (
        <>


            <section className="section section-height-4 section-with-shape-divider bg-color-grey-scale-1 border-0 pb-5 m-0">


                <div className="shape-divider" style={{ height: '123px' }}>
                    
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 1920 123" preserveAspectRatio="xMinYMin">
                        <polygon fill="#F3F3F3" points="0,90 221,60 563,88 931,35 1408,93 1920,41 1920,-1 0,-1 "></polygon>
                        <polygon fill="#FFFFFF" points="0,75 219,44 563,72 930,19 1408,77 1920,25 1920,-1 0,-1 "></polygon>


                    </svg>
                </div>
                <div className="container mt-4">

                    <div className="row justify-content-center">

                        <div className="col-lg-11 col-xl-10 text-center">


                            <h2 className="custom-highlight-text-1 d-inline-block line-height-5 text-4 positive-ls-3 font-weight-medium text-color-primary mb-2 appear-animation animated fadeInUpShorter appear-animation-visible"
                                data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250"
                                style={{ animationDelay: '250ms' }}>WHAT WE DO
                            </h2>
                            <h3 className="text-9 line-height-3 text-transform-none font-weight-semibold mb-3 pb-1 appear-animation animated fadeInUpShorter appear-animation-visible"
                                data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500"
                                style={{ animationDelay: '500ms' }}>Our Premium
                                Services</h3>
                            <p className="text-3-5 pb-3 mb-4 appear-animation animated fadeInUpShorter appear-animation-visible"
                                data-appear-animation="fadeInUpShorter" data-appear-animation-delay="750"
                                style={{ animationDelay: '750ms' }}>"Your trusted startup advisor, unlocking the power of
                                AI, IoT, web and app development, and automation services to drive your success in the
                                digital age."</p>

                        </div>
                    </div>

                    <div className="row row-gutter-sm justify-content-center mb-5 appear-animation animated fadeInUpShorter appear-animation-visible"
                        data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000"
                        style={{ animationDelay: '1000ms' }}>

                        

                        <div className="col-sm-9 col-md-6 col-lg-4 mb-4">

                         
                            <Link to="services" onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none"
                                data-cursor-effect-hover="plus">
                                <div className="card box-shadow-4">
                                    <div className="card-img-top position-relative overlay overlay-show">
                                        <div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
                                            <h4 className="font-weight-semibold text-color-light text-6 mb-1">New StarUp
                                                Consultant
                                            </h4>
                                            <div className="custom-crooked-line">
                                                <img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
                                                    alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
                                                    style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />


                                            </div>

                                        </div>

                                        <img src="assets/img/demos/business-consulting-3/services/new star up.png"
                                            className="img-fluid" alt="Card Image" />



                                    </div>

                                    <div className="card-body d-flex align-items-center custom-view-more px-4">
                                        <p className="card-text w-100 mb-0">Navigating success for startups with expert
                                            guidance and tailored strategies. </p>
                                        <img width="50" height="50" className="w-auto svg-fill-color-primary"
                                            src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt=""
                                            data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
                                            style={{ width: '50px', opacity: '1' }} />



                                    </div>

                                </div>


                            </Link>

                        </div>

                        <div className="col-sm-9 col-md-6 col-lg-4 mb-4 mb-lg-0">
                            <Link to="services"  onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none"
                                data-cursor-effect-hover="plus">
                                <div className="card box-shadow-4">
                                    <div className="card-img-top position-relative overlay overlay-show">
                                        <div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
                                            <h4 className="font-weight-semibold text-color-light text-6 mb-1">Artificial
                                                Intelligence </h4>
                                            <div className="custom-crooked-line">
                                                <img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
                                                    alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
                                                    style={{ width: '154p', opacity: '1' }} className="svg-fill-color-primary" />



                                            </div>


                                        </div>

                                        <img src="assets/img/demos/business-consulting-3/services/Art in.png"
                                            className="img-fluid" alt="Card Image" />




                                    </div>

                                    <div className="card-body d-flex align-items-center custom-view-more px-4">
                                        <p className="card-text w-100 mb-0">Artificial Intelligence to enhance your business
                                            operations, data analysis, and decision-making. </p>
                                        <img width="50" height="50" className="w-auto svg-fill-color-primary"
                                            src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt="" data-icon=""
                                            data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
                                            style={{ width: '50px', opacity: '1' }} />




                                    </div>

                                </div>

                            </Link>

                        </div>

                        <div className="col-sm-9 col-md-6 col-lg-4 mb-4 mb-lg-0">
                            <Link to="services" onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none"
                                data-cursor-effect-hover="plus">
                                <div className="card box-shadow-4">
                                    <div className="card-img-top position-relative overlay overlay-show">
                                        <div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
                                            <h4 className="font-weight-semibold text-color-light text-6 mb-1">Internet of
                                                things </h4>
                                            <div className="custom-crooked-line">
                                                <img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
                                                    alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
                                                    style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />


                                            </div>

                                        </div>

                                        <img src="assets/img/demos/business-consulting-3/services/IOT.png" className="img-fluid" alt="Card Image" />


                                    </div>
                                    <div className="card-body d-flex align-items-center custom-view-more px-4">
                                        <p className="card-text w-100 mb-0">IoT services provide connectivity and insights
                                            to optimize processes and enhance productivity. </p>
                                        <img width="50" height="50" className="w-auto svg-fill-color-primary"
                                            src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt="" data-icon=""
                                            data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
                                            style={{ width: '50px', opacity: '1' }} />

                                    </div>

                                </div>
                            </Link>

                        </div>

                        <div className="col-sm-9 col-md-6 col-lg-4 mb-4">
                            <Link to="services"  onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none"
                                data-cursor-effect-hover="plus">
                                <div className="card box-shadow-4">
                                    <div className="card-img-top position-relative overlay overlay-show">
                                        <div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
                                            <h4 className="font-weight-semibold text-color-light text-6 mb-1">Web
                                                Development</h4>
                                            <div className="custom-crooked-line">

                                                <img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
                                                    alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
                                                    style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />


                                            </div>

                                        </div>
                                        <img src="assets/img/demos/business-consulting-3/services/website development.png"
                                            className="img-fluid" alt="Card Image" />



                                    </div>
                                    <div className="card-body d-flex align-items-center custom-view-more px-4">
                                        <p className="card-text w-100 mb-0">Crafting engaging and user-centric websites to
                                            boost your online presence and drive digital success.</p>
                                        <img width="50" height="50" className="w-auto svg-fill-color-primary"
                                            src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt="" data-icon=""
                                            data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
                                            style={{ width: '50px', opacity: '1' }} />


                                    </div>

                                </div>

                            </Link>
                        </div>

                        
                        <div className="col-sm-9 col-md-6 col-lg-4 mb-4">
                            <Link to="services"  onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none"
                                data-cursor-effect-hover="plus">
                                <div className="card box-shadow-4">
                                    <div className="card-img-top position-relative overlay overlay-show">
                                        <div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
                                            <h4 className="font-weight-semibold text-color-light text-6 mb-1">App
                                                Development</h4>
                                            <div className="custom-crooked-line">
                                                <img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
                                                    alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
                                                    style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />
                                            </div>
                                        </div>
                                        <img src="assets/img/demos/business-consulting-3/services/android development.png"
                                            className="img-fluid" alt="Card Image" />
                                    </div>
                                    <div className="card-body d-flex align-items-center custom-view-more px-4">
                                        <p className="card-text w-100 mb-0">Transform your ideas into user-friendly,
                                            feature-rich mobile App for the Android platform. </p>
                                        <img width="50" height="50" className="w-auto svg-fill-color-primary"
                                            src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt="" data-icon=""
                                            data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
                                            style={{ width: '50px', opacity: '1' }} />
                                    </div>
                                </div>
                            </Link>
                        </div>
                        

                        <div className="col-sm-9 col-md-6 col-lg-4">
                            <Link to="services" onClick={handleCLickLink}
                                className="custom-link-hover-effects text-decoration-none" data-cursor-effect-hover="plus">
                                <div className="card box-shadow-4">
                                    <div className="card-img-top position-relative overlay overlay-show">
                                        <div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
                                            <h4 className="font-weight-semibold text-color-light text-6 mb-1">Automation
                                            </h4>
                                            <div className="custom-crooked-line">
                                                <img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
                                                    alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
                                                    style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />
                                            </div>
                                        </div>
                                        <img src="assets/img/demos/business-consulting-3/services/automition.png"
                                            className="img-fluid" alt="Card Image" />
                                    </div>
                                    <div className="card-body d-flex align-items-center custom-view-more px-4">
                                        <p className="card-text w-100 mb-0">Revolutionizing your workflow with intelligent,
                                            time-saving processes and solutions. </p>
                                        <img width="50" height="50" className="w-auto svg-fill-color-primary"
                                            src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt="" data-icon=""
                                            data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
                                            style={{ width: '50px', opacity: '1' }} />
                                    </div>
                                </div>
                            </Link>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col text-center">
                            <Link to="services"  onClick={handleCLickLink} className="btn btn-primary custom-btn-style-1 font-weight-semibold btn-px-4 btn-py-2 text-3-5"
                                data-cursor-effect-hover="plus" data-cursor-effect-hover-color="light">
                                <span>All Services</span>
                            </Link>
                        </div>
                    </div>


                </div>
            </section>


        </>
    )
}

export default Tempo4