import React from 'react'
import Tempo from '../components/Tempo'
import Tempo2 from '../components/Tempo2'
import Tempo4 from '../components/Tempo4'
import Tempo5 from '../components/Tempo5'
import Tempo6 from '../components/Tempo6'
import Tempo9 from '../components/Tempo9'
import Tempo11 from '../components/Tempo11'
import Owl from '../components/Owl'
import OwlTempo8 from '../components/OwlTempo8'
import OwlTempo10 from '../components/OwlTempo10'
import './Home.css'



const Home = () => {
	return (
		<>
			<div data-plugin-cursor-effect>
				<div className="body" >
					

					<div role="main" className="main">

						<Tempo />

						<Tempo2 />

						<Owl />

						

						<Tempo4 />

						<Tempo5 />

						<Tempo6 />

						


						<OwlTempo8 />
					

						<Tempo9 />


						<OwlTempo10 />
						

						<Tempo11 />


					

					</div>

					
				</div>



				{/* <!-- Vendor --> */}
				<script data-cfasync="false" src="assets/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script>
				<script src="assets/vendor/plugins/js/plugins.min.js"></script>

				{/* <!-- Theme Base, Components and Settings --> */}
				<script src="assets/js/theme.js"></script>

				{/* <!-- Current Page Vendor and Views --> */}
				<script src="assets/js/views/view.contact.js"></script>

				{/* <!-- Theme Initialization Files --> */}
				<script src="assets/js/theme.init.js"></script>

				<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAhpYHdYRY2U6V_VfyyNtkPHhywLjDkhfg"></script>



			</div>

		</>)
}

export default Home