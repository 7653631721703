import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './OwlMobile.css';
import { Link } from 'react-router-dom';

const OwlMobile = () => {
  return (
   <>
   <OwlCarousel className= 'owl-mobile owl-theme owl-carousel owl-theme custom-carousel-box-shadow-1 custom-dots-style-1 mb-0 owl-loaded owl-drag owl-carousel-init'
    responsive={{
        0:{items:1},
        768:{items:1},
        992:{items:1},
        1200:{items:1}
      }}
   loop margin={10} nav={false} >
   <div className="item" style={{ width: '310.5px', marginRight: '20px' }}><div>
								<Link to="services" className="custom-link-hover-effects text-decoration-none" data-cursor-effect-hover="plus">
									<div className="card border-0">
										<div className="card-body text-center py-5">
											<img width="25vh" height="25vh" src="assets/img/demos/business-consulting-3/icons/AI icon.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" style={{ width: '25vh', height: '75px', opacity: '1' }} className="svg-fill-color-primary" />
											<div className="custom-crooked-line">
												<img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" style={{ width: '154px', height: '26px', opacity: '1', }} className="svg-fill-color-primary" />
											</div>
											<h2 className="text-5 font-weight-semibold mb-1">Artificial Intelligence </h2>
											<p className="pb-1 mb-2"> Boost business with artificial intelligence. </p>
											<span className="custom-view-more d-inline-flex font-weight-medium text-color-primary">
												View More
												<img width="27" height="27" src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary ms-2'}" style={{ width: '27px', opacity: '1' }} className="svg-fill-color-primary ms-2" />
											</span>
										</div>
									</div>
								</Link>
							</div></div>
                            <div className="item" style={{ width: '310.5px', marginRight: '20px' }}><div>
								<Link to="services" className="custom-link-hover-effects text-decoration-none" data-cursor-effect-hover="plus">
									<div className="card border-0">
										<div className="card-body text-center py-5">
											<img width="25vh" height="25vh" src="assets/img/demos/business-consulting-3/icons/development.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" style={{ width: '25vh', height: '75px', opacity: '1' }} className="svg-fill-color-primary" />
											<div className="custom-crooked-line">
												<img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" style={{ width: '154px', height: '26px', opacity: '1' }} className="svg-fill-color-primary" />
											</div>
											<h2 className="text-5 font-weight-semibold mb-1">Development</h2>
											<p className="pb-1 mb-2">Creating innovative software for evolving business
												needs.</p>
											<span className="custom-view-more d-inline-flex font-weight-medium text-color-primary">
												View More
												<img width="27" height="27" src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary ms-2'}" style={{ width: '27px', opacity: '1' }} className="svg-fill-color-primary ms-2" />
											</span>
										</div>
									</div>
								</Link>
							</div></div>

</OwlCarousel>
   </>
  )
}

export default OwlMobile