import React from 'react'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const Owl = () => {
    return (
        <>
            <div className="container-fluid pt-5 mt-5 mb-4">
                <div className="row">
                    <div className="col appear-animation animated fadeInUpShorter appear-animation-visible"
                        data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250"
                        style={{ animationDelay: '250ms' }}>




                        <OwlCarousel
                            data-plugin-options="{'responsive': {'0': {'items': 1}, '476': {'items': 3}, '768': {'items': 3}, '992': {'items': 5}, '1200': {'items': 5}, '1600': {'items': 7}}, 
 'autoplay': false, 'autoplayTimeout': 3000, 'dots': true}"

                            className='owl-theme owl-carousel  custom-carousel-vertical-center-items custom-dots-style-1 owl-loaded  owl-drag owl-carousel-init'
                            loop margin={10} nav={true} dots={true} style={{ height: 'auto' }} >







                            <div className="text-center">

                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-7.png" alt="" style={{ maxWidth: '140px' }} />

                            </div>











                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-8.png" alt="" style={{ maxWidth: '200px' }} />
                            </div>




                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-9.png" alt="" style={{ maxWidth: '200px' }} />
                            </div>





                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-10.png" alt="" style={{ maxWidth: '110px' }} />
                            </div>




                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-11.png" alt="" style={{ maxWidth: '110px' }} />
                            </div>


                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-12.png" alt="" style={{ maxWidth: '110px' }} />
                            </div>




                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-13.png" alt="" style={{ maxWidth: '110px' }} />
                            </div>



                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-1.png" alt="" style={{ maxWidth: '90px' }} />
                            </div>


                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-2.png" alt="" style={{ maxWidth: '140px' }} />
                            </div>

                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-3.png" alt="" style={{ maxWidth: '140px' }} />
                            </div>

                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-4.png" alt="" style={{ maxWidth: '140px' }} />
                            </div>

                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-5.png" alt="" style={{ maxWidth: '100px' }} />
                            </div>

                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-6.png" alt="" style={{ maxWidth: '100px' }} />
                            </div>

                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-7.png" alt="" style={{ maxWidth: '140px' }} />
                            </div>

                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-8.png" alt="" style={{ maxWidth: '200px' }} />
                            </div>

                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-9.png" alt="" style={{ maxWidth: '200px' }} />
                            </div>

                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-10.png" alt="" style={{ maxWidth: '110px' }} />
                            </div>

                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-11.png" alt="" style={{ maxWidth: '110px' }} />
                            </div>

                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-12.png" alt="" style={{ maxWidth: '110px' }} />
                            </div>

                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-13.png" alt="" style={{ maxWidth: '110px' }} />
                            </div>

                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-1.png" alt="" style={{ maxWidth: '90px' }} />
                            </div>


                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-2.png" alt="" style={{ maxWidth: '140px' }} />
                            </div>

                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-3.png" alt="" style={{ maxWidth: '140px' }} />
                            </div>

                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-4.png" alt="" style={{ maxWidth: '140px' }} />
                            </div>

                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-5.png" alt="" style={{ maxWidth: '100px' }} />
                            </div>

                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-6.png" alt="" style={{ maxWidth: '100px' }} />
                            </div>

                            <div className="text-center">
                                <img className="d-inline-block img-fluid" src="assets/img/logos/logo-7.png" alt="" style={{ maxWidth: '140px' }} />
                            </div>













                        </OwlCarousel>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Owl