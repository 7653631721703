import React from 'react'
import Owl from "../components/Owl"
import { Link, NavLink } from 'react-router-dom'

const About = () => {
function handleCLickLink(){
	window.scrollTo(0,0);
}

	return (<>
		<div data-plugin-cursor-effect>
			{/* <div className="cursor-inner" style={{ transform: 'translate(454px, 368px)' }}></div>
			<div className="cursor-outer" style={{ transform: 'translate(303px, 57px)' }}></div> */}

			<div className="body">


				<div role="main" className="main">


					<section className="section section-with-shape-divider page-header page-header-modern page-header-lg border-0 my-0 lazyloaded"
						data-bg-src="assets/img/demos/business-consulting-3/backgrounds/background-5.jpg"
						style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}>


						<div className="container pb-5 my-3">


							<div className="row mb-4">


								<div className="col-md-12 align-self-center p-static order-2 text-center">


									<h1 className="font-weight-bold text-color-dark text-10">About Us</h1>
								</div>
								<div className="col-md-12 align-self-center order-1">

									<ul className="breadcrumb d-block text-center">
										<li><NavLink onClick={handleCLickLink} to="/" style={{textDecoration:'none',color:'#F04C23'}}>Home</NavLink></li>
										<li className="active">About Us</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="shape-divider shape-divider-bottom shape-divider-reverse-x" style={{ height: '123px' }}>


							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 123" preserveAspectRatio="xMinYMin">
								<polygon fill="#F3F3F3" points="0,90 221,60 563,88 931,35 1408,93 1920,41 1920,-1 0,-1 " />
								<polygon fill="#FFFFFF" points="0,75 219,44 563,72 930,19 1408,77 1920,25 1920,-1 0,-1 " />
							</svg>
						</div>
					</section>

					<div className="container pt-3 mt-4">

						<div className="row">

							<div className="col">

								<h2 className="text-9 text-lg-5 text-xl-9 line-height-3 text-transform-none font-weight-semibold mb-4 mb-lg-3 mb-xl-4 appear-animation animated fadeInUpShorter appear-animation-visible"
									data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250"
									style={{ animationDelay: '250ms' }}>Dualsysco R&amp;D</h2>

								<p className="text-3-5 appear-animation animated fadeInUpShorter appear-animation-visible"
									data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500"
									style={{ animationDelay: '500ms' }}>Established in
									2014
									, Dualsysco is an innovative IT company
									specializing in cutting
									-edge research and development
									. Our
									expertise extends to guiding startups from conception to
									deployment, excelling in software, app development, AI
									architecture, CRM, ERP, and Mobile Application development
									.
									As pioneers in AI and Machine Learning integration, we empower
									businesses with unparalleled efficiency and intelligence
									.
									Trusted by both Private and Government sectors, we offer
									comprehensive support, from refining ideas to post
									-production
									services, ensuring exceptional solutions and customer satisfaction
									.
									Committed to staying at the forefront of the rapidly evolving IT
									industry, we continuously invest in research and development,
									including IoT and Big Data technologies
									.
									Partner with Dualsysco to shape the future with innovative
									technological solutions
								</p>



								<div className="row row-gutter-sm align-items-center pb-3 mb-5">

									<div className="col-lg-6 col-xl-7">

										<div className="row flex-xl-nowrap align-items-center mb-4 mb-lg-0">

											<div className="col-sm-auto mb-4 mb-sm-0">

												<div className="d-flex appear-animation animated fadeInRightShorter appear-animation-visible"
													data-appear-animation="fadeInRightShorter" data-appear-animation-delay="1000"
													style={{ animationDelay: '1000ms' }}>


													<img width="63" height="63" src="assets/img/demos/business-consulting-3/icons/label.svg" alt=""
														data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'd-lg-none d-xl-block'}"
														className="d-lg-none d-xl-block" style={{ opacity: '1', width: '63px' }} />

													<span className="text-3 font-weight-bold text-color-dark pt-2 ms-3">
														<strong className="d-block font-weight-bold text-10 mb-0">240+</strong>
														Satisfied Clients
													</span>
												</div>
											</div>
											<div className="col-sm-7 col-md-8">


												<blockquote className="custom-blockquote-style-1 m-0 pt-1 pb-2 appear-animation animated fadeInRightShorter appear-animation-visible" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="1200"
													style={{ animationDelay: '1200ms' }}
												>
													<p className="mb-0">Join us on the journey to harness the power of AI and development to achieve your business goals.</p>
												</blockquote>
											</div>
										</div>
									</div>



									<div className="col-lg-6 col-xl-5" >
										<div className="row row-gutter-sm align-items-center" 
										style={{display:'flex', justifyContent:'space-between' }}>
											<div className="col-auto appear-animation animated fadeInRightShorter appear-animation-visible"
												data-appear-animation="fadeInRightShorter" data-appear-animation-delay="1400"
												style={{ animationDelay: '1400ms' ,flex:'1', whiteSpace:'nowrap'}}>


												<Link to="/contact" onClick={handleCLickLink} className="btn btn-primary custom-btn-style-1 font-weight-semibold btn-px-3 btn-py-2 text-3-5" data-cursor-effect-hover="plus" data-cursor-effect-hover-color="light"><span>Get a Quote</span></Link>
											</div>

											<div className="col-auto appear-animation animated fadeInRightShorter appear-animation-visible"
												data-appear-animation="fadeInRightShorter" data-appear-animation-delay="1600"
												style={{ animationDelay: '1600ms' ,flex:'1', whiteSpace:'nowrap'}}>


												<Link to="/contact" onClick={handleCLickLink} className="btn btn-dark custom-btn-style-1 font-weight-semibold btn-px-3 btn-py-2 text-3-5" data-cursor-effect-hover="plus" data-cursor-effect-hover-color="light"><span>Contact Us</span></Link>
											</div>
											<div className="col-auto mt-4 mt-sm-0 appear-animation animated fadeInRightShorter appear-animation-visible"
												data-appear-animation="fadeInRightShorter" data-appear-animation-delay="1800"
												style={{ animationDelay: '1800ms' , flex:'1'}}>

												<span className="d-flex align-items-center p-0">
													<span>
														<img width="25" height="25" src="assets/img/demos/business-consulting-3/icons/phone.svg" alt="Phone Icon" data-icon=""
															data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-dark'}" className="svg-fill-color-dark"
															style={{ opacity: '1', width: '25px' }} />

													</span>
													<a className="text-color-dark text-color-hover-primary text-decoration-none font-weight-semibold text-3 ms-2" href="tel:9822112848" >+91 9822112848</a>
												</span>
											</div>
										</div>
									</div>
								
								
								
								
								</div>
							</div>
						</div>
						<div className="row row-gutter-sm">
							<div className="col-sm-6 mb-4 mb-sm-0">
								<img src="assets/img/demos/business-consulting-3/generic/generic-5.jpg"
									className="img-fluid box-shadow-5 appear-animation animated fadeInRightShorter appear-animation-visible"
									data-appear-animation="fadeInRightShorter" data-appear-animation-delay="800" alt=""
									style={{ animationDelay: '800ms' }} />


							</div>
							<div className="col-6 col-sm-3">
								<img src="assets/img/demos/business-consulting-3/generic/generic-11.jpg"
									className="img-fluid box-shadow-5 appear-animation animated fadeInRightShorter appear-animation-visible"
									data-appear-animation="fadeInRightShorter" data-appear-animation-delay="600" alt=""
									style={{ animationDelay: '600ms' }} />


							</div>
							<div className="col-6 col-sm-3">
								<img src="assets/img/demos/business-consulting-3/generic/generic-2.jpg"
									className="img-fluid box-shadow-5 mb-4 appear-animation animated fadeInRightShorter appear-animation-visible"
									data-appear-animation="fadeInRightShorter" data-appear-animation-delay="200" alt=""
									style={{ animationDelay: '200ms' }} />

								<img src="assets/img/demos/business-consulting-3/generic/generic-3.jpg"
									className="img-fluid box-shadow-5 appear-animation animated fadeInRightShorter appear-animation-visible"
									data-appear-animation="fadeInRightShorter" data-appear-animation-delay="400" alt=""
									style={{ animationDelay: '400ms' }} />

							</div>
						</div>
					</div>

					<Owl />



					<section className="section section-height-4 section-with-shape-divider bg-color-grey-scale-1 border-0 pb-5 m-0">
						<div className="shape-divider" style={{ height: '123px' }}>
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 123" preserveAspectRatio="xMinYMin">
								<polygon fill="#F3F3F3" points="0,90 221,60 563,88 931,35 1408,93 1920,41 1920,-1 0,-1 " />
								<polygon fill="#FFFFFF" points="0,75 219,44 563,72 930,19 1408,77 1920,25 1920,-1 0,-1 " />
							</svg>
						</div>
						<div className="container pb-5">
							<div className="row align-items-center py-2 my-5 mb-lg-0 my-xl-5">
								<div className="col-lg-7 mb-5 mb-lg-0">
									<h2 className="text-9 line-height-3 text-transform-none font-weight-semibold mb-4">How We Works</h2>
									<p className="text-3-5 pb-3 mb-4">Cultivate essential skills that drive significant business impact,
										from innovation to strategic planning, ensuring meaningful growth and success.</p>
									<div className="progress-bars pb-5">
										<div className="progress-label d-flex justify-content-between">
											<span className="text-color-dark font-weight-semibold text-2">REQUIREMENT ELICITATION</span>
											<span className="text-color-dark font-weight-semibold text-2">100%</span>
										</div>
										<div className="progress progress-xs progress-no-border-radius bg-color-grey-scale-2 mb-4">
											<div className="progress-bar progress-bar-primary" data-appear-progress-animation="100%"
												style={{ width: '100%' }}></div>
										</div>

										<div className="progress-label d-flex justify-content-between pt-2">
											<span className="text-color-dark font-weight-semibold text-2">PRODUCTION</span>
											<span className="text-color-dark font-weight-semibold text-2">100%</span>
										</div>
										<div className="progress progress-xs progress-no-border-radius bg-color-grey-scale-2 mb-4">
											<div className="progress-bar progress-bar-primary" data-appear-progress-animation="100%"
												style={{ width: '100%' }}></div>
										</div>

										<div className="progress-label d-flex justify-content-between pt-2">
											<span className="text-color-dark font-weight-semibold text-2">DEPLOYMENT</span>
											<span className="text-color-dark font-weight-semibold text-2">100%</span>
										</div>
										<div className="progress progress-xs progress-no-border-radius bg-color-grey-scale-2 mb-4">
											<div className="progress-bar progress-bar-primary" data-appear-progress-animation="100%"
												style={{ width: '100%' }}></div>
										</div>
									</div>
								</div>
								<div className="col-lg-5">
									<img src="assets/img/demos/business-consulting-3/generic/generic-4-grey.jpg" className="img-fluid" alt="" />
								</div>
							</div>
						</div>
						<div className="shape-divider shape-divider-bottom shape-divider-reverse-x" style={{ height: '123px' }}>
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 123" preserveAspectRatio="xMinYMin">
								<polygon fill="#F3F3F3" points="0,90 221,60 563,88 931,35 1408,93 1920,41 1920,-1 0,-1 " />
								<polygon fill="#FFFFFF" points="0,75 219,44 563,72 930,19 1408,77 1920,25 1920,-1 0,-1 " />
							</svg>
						</div>
					</section>




					<div className="container py-5 mb-5">
						<div className="row">
							<div className="col">

								<div className="tabs tabs-bottom tabs-center tabs-simple custom-tabs-style-1">
									<ul className="nav nav-tabs" role="tablist">
										<li className="nav-item active" role="presentation">
											<a className="nav-link active" href="#tabsNavigationManifesto" data-bs-toggle="tab" aria-selected="false" role="tab" tabIndex="-1">Manifesto</a>
										</li>
										<li className="nav-item" role="presentation">
											<a className="nav-link" href="#tabsNavigationVision" data-bs-toggle="tab" aria-selected="true" role="tab">Vision</a>
										</li>
										<li className="nav-item" role="presentation">
											<a className="nav-link" href="#tabsNavigationMission" data-bs-toggle="tab" aria-selected="false" role="tab" tabIndex="-1">Mission</a>
										</li>
									</ul>
									<div className="tab-content">
										<div className="tab-pane active show" id="tabsNavigationManifesto" role="tabpanel">
											<div className="text-center">
												<p className="text-3-5">At Dualsysco R&D, we are driven by an unyielding commitment to innovation and ethical excellence. Our mission is to pioneer cutting-edge solutions that empower businesses and individuals while upholding the highest standards of transparency, privacy, and security. We believe in the transformative potential of AI and software, and we continuously strive to lead the way in this dynamic industry. Collaboration and connection are at the heart of our culture, as we work hand-in-hand with our valued partners and clients to co-create impactful solutions.</p>
												<p className="text-3">Our manifesto is rooted in a dedication to lifelong learning and mastery, ensuring that our team remains at the forefront of AI and software development. We are client-centric in our approach, tailoring our solutions to meet unique needs and providing unwavering support. As we envision a future where technology drives progress and inclusivity, we are committed to environmental responsibility, developing eco-friendly solutions, and promoting equal access for all. Our legacy is one of excellence, setting the standard for quality, ethics, and innovation in the AI and software development landscape. </p>
											</div>
										</div>
										<div className="tab-pane" id="tabsNavigationVision" role="tabpanel">
											<div className="text-center">
												<p className="text-3-5">At Dualsysco R&D, our vision is to be the catalyst for a future where artificial intelligence and software development revolutionize industries, solve complex challenges, and enrich lives globally. We aspire to lead the charge in pioneering innovative technologies that empower businesses and individuals to achieve their full potential. By fostering a culture of continuous learning, ethical integrity, and unwavering dedication to excellence, we aim to set new benchmarks in the industry and become synonymous with cutting-edge AI and software solutions.</p>
												<p className="text-3">In our vision, we see a world where technology is a force for good, where inclusivity is paramount, and where environmental responsibility guides our actions. We envision a future where our contributions leave a lasting positive impact on society, where businesses thrive through our bespoke software solutions, and where individuals experience the transformative benefits of AI in their daily lives. Through relentless innovation, collaboration, and a steadfast commitment to our core values, we strive to shape a future where the possibilities of AI and software development are limitless.</p>
											</div>
										</div>
										<div className="tab-pane" id="tabsNavigationMission" role="tabpanel">
											<div className="text-center">
												<p className="text-3-5">Our mission at Dualsysco R&D is to harness the power of artificial intelligence and software development to drive meaningful change in the world. We are committed to creating innovative solutions that solve complex problems, enhance productivity, and improve the quality of life for businesses and individuals alike. Our mission is to be at the forefront of technological advancements, leading the way in AI and software development while maintaining the highest ethical standards.</p>
												<p className="text-3">We strive to empower our clients by delivering tailor-made software solutions that meet their unique needs and propel their success. Our mission is rooted in a dedication to lifelong learning, enabling our team to remain experts in their field and provide unmatched expertise to our clients. We are guided by a client-centric approach, environmental responsibility, and a vision of inclusivity, ensuring that the benefits of AI and software development are accessible to all. Our mission is to make a lasting impact on the industry, setting new standards for excellence and innovation while contributing positively to society's future.</p>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>



					

				</div>


			</div>



			{/* <!-- Vendor --> */}
			<script data-cfasync="false" src="assets/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script><script src="assets/vendor/plugins/js/plugins.min.js"></script>

			{/* <!-- Theme Base, Components and Settings --> */}
			<script src="assets/js/theme.js"></script>

			{/* <!-- Current Page Vendor and Views --> */}
			<script src="assets/js/views/view.contact.js"></script>

			{/* <!-- Theme Initialization Files --> */}
			<script src="assets/js/theme.init.js"></script>

			<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAhpYHdYRY2U6V_VfyyNtkPHhywLjDkhfg"></script>



		</div>

	</>)
}

export default About