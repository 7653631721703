import React from 'react'

const Tempo6 = () => {
  return (
    <>


      <div className="container">
        <div className="row align-items-center py-4 my-5 mb-lg-0 my-xl-5">
          <div className="col-lg-7 mb-5 mb-lg-0">
            <h2 className="text-9 line-height-3 text-transform-none font-weight-semibold mb-4">How We Works</h2>

            <p className="text-3-5 pb-3 mb-4">Cultivate essential skills that drive significant business impact,
              from innovation to strategic planning, ensuring meaningful growth and success.</p>

            <div className="progress-bars pb-4">
              <div className="progress-label d-flex justify-content-between">
                <span className="text-color-dark font-weight-semibold text-2">REQUIREMENT ELICITATION</span>
                <span className="text-color-dark font-weight-semibold text-2">100%</span>

              </div>

              <div className="progress progress-xs progress-no-border-radius bg-color-light-scale-1 mb-4">
                <div className="progress-bar progress-bar-primary"
                  data-appear-progress-animation="100%"
                  style={{ width: '100%' }}>

                </div>

              </div>
              <hr className="my-0" />
              <div className="progress-label d-flex justify-content-between pt-2">
                <span className="text-color-dark font-weight-semibold text-2">PRODUCTION</span>
                <span className="text-color-dark font-weight-semibold text-2">100%</span>
              </div>

              <div className="progress progress-xs progress-no-border-radius bg-color-light-scale-1 mb-4">
                <div className="progress-bar progress-bar-primary" data-appear-progress-animation="100%"
                  style={{ width: '100%' }}>


                </div>
              </div>
              <hr className="my-0" />

              <div className="progress-label d-flex justify-content-between pt-2">
                <span className="text-color-dark font-weight-semibold text-2">DEPLOYMENT</span>
                <span className="text-color-dark font-weight-semibold text-2">100%</span>
              </div>

              <div className="progress progress-xs progress-no-border-radius bg-color-light-scale-1 mb-4">
                <div className="progress-bar progress-bar-primary" data-appear-progress-animation="100%"
                  style={{ width: '100%' }}>
                </div>
              </div>

            </div>
          </div>

          <div className="col-lg-5">
            <img src="assets/img/demos/business-consulting-3/generic/generic-4.jpg"
              className="img-fluid" alt="" />
          </div>

        </div>

      </div>

    </>
  )
}

export default Tempo6