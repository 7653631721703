


import './Header.css'
import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'

const Header = () => {
	const [q, setQ] = useState('');
	const pluginOptions = {
		stickyEnabled: true,
		stickyEffect: "shrink",
		stickyEnableOnBoxed: true,
		stickyEnableOnMobile: false,
		stickyChangeLogo: true,
		stickyStartAt: 120,
		stickyHeaderContainerHeight: 85,
	  };

	  function handleCLickLink (){
		window.scrollTo(0,0);
	}

	  const [overflow, setOverflow] = useState("visible");
  const [height, setHeight] = useState("53.0469px");
  const [minHeight, setMinHeight] = useState("0px");


  useEffect(() => {
    window.addEventListener("scroll", function() {
      if (window.scrollY > 0) {
        setOverflow("hidden");
        setHeight("0");
		setMinHeight('0');
      } else {
        setOverflow("visible");
        setHeight("53.0469px");
		setMinHeight('0');
      }
    });
  }, []);
	
	

	return (<>
		 <header
      id="header"
      className="header-effect-shrink"
      data-plugin-options={JSON.stringify(pluginOptions)}
      style={{ height: "171.047px" }}
    >
			<div className="header-body border-top-0" style={{ position: "fixed", top: 0 }}>
			<div
      className="header-top header-top-default header-top-borders border-bottom-0 bg-color-light"
      style={{ overflow, height, minHeight }}
    >
					<div className="container">
						<div className="header-row">
							<div className="header-column justify-content-between">
								<div className="header-row">
									<nav className="header-nav-top w-100 w-md-50pct w-xl-100pct">
										<ul className="nav nav-pills d-inline-flex custom-header-top-nav-background pe-5">
											<li className="nav-item py-2 d-inline-flex z-index-1">
												<span className="d-flex align-items-center p-0">
													<span>
														<img width="25"
															src="assets/img/demos/business-consulting-3/icons/phone.svg"
															alt="Phone Icon" data-icon
															data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light'}" />
													</span>
													<a className="text-color-light text-decoration-none font-weight-semibold text-3-5 ms-2"
														href="tel:9822112848" data-cursor-effect-hover="plus"
														data-cursor-effect-hover-color="light">+91 9822112848</a>
												</span>

												<span
													className="font-weight-normal align-items-center px-0 d-none d-xl-flex ms-3">
													<span>
														<img width="25"
															src="assets/img/demos/business-consulting-3/icons/email.svg"
															alt="Email Icon" data-icon
															data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light'}" />
													</span>
													<a className="text-color-light text-decoration-none font-weight-semibold text-3-5 ms-2"
														href="https://www.okler.net/cdn-cgi/l/email-protection#c6a4b3b5afa8a3b5b586b6a9b4b2a9b2aea3aba3e8a5a9ab"
														data-cursor-effect-hover="plus"
														data-cursor-effect-hover-color="light"><span
															className="__cf_email__">info@dualsysco.com</span></a>
												</span>
											</li>
										</ul>
									</nav>
									<div className="d-flex align-items-center w-100">
										<ul className="ps-0 ms-auto mb-0">
											<li
												className="nav-item font-weight-semibold text-1 text-lg-2 text-color-dark d-none d-md-flex justify-content-end me-3">
												Mon - Sat 10:00am - 7:00pm / Sunday - CLOSED
											</li>
										</ul>
										<ul
											className="social-icons social-icons-clean social-icons-icon-dark social-icons-big m-0 ms-lg-2">
											<li className="social-icons-instagram">
												<a href="http://www.instagram.com/" target="_blank" className="text-4"
													title="Instagram" data-cursor-effect-hover="fit"><i
														className="fab fa-instagram"></i></a>
											</li>
											<li className="social-icons-linkedin">
												<a href="https://www.linkedin.com/company/dualsysco-research-and-development/" target="_blank" className="text-4"
													title="linkedin" data-cursor-effect-hover="fit"><i
														className="fab fa-linkedin-in"></i></a>
											</li>
											<li className="social-icons-facebook">
												<a href="http://www.facebook.com/" target="_blank" className="text-4"
													title="Facebook" data-cursor-effect-hover="fit"><i
														className="fab fa-facebook-f"></i></a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			
			
				<div className=" header-container container " style={{ height: '117px' }}>
					<div className="header-row ">
						<div className="header-column">
							<div className="header-row">
								<div className="header-logo">
									<Link to="/" onClick={() => window.scrollTo(0, 0)}>
										<img alt="Dualsysco" width="150" height="55"
											src="assets/img/demos/business-consulting-3/Dualsysco-Logo.png" />
									</Link>
								</div>
							</div>
						</div>
						<div className="header-column justify-content-end w-100">
							<div className="header-row">
								<div className="header-nav header-nav-links order-2 order-lg-1">
									<div
										className="header-nav-main header-nav-main-square header-nav-main-text-capitalize header-nav-main-effect-1 header-nav-main-sub-effect-1">
										<nav className="collapse">
											<ul className="nav nav-pills" id="mainNav">
												<li>
													<NavLink activeClassName="nav-link active" to="/" onClick={() => window.scrollTo(0, 0)}>
														Home
													</NavLink>
												</li>
												<li>
													<NavLink activeClassName="nav-link" to="/about" onClick={() => window.scrollTo(0, 0)}>
														About Us
													</NavLink>
												</li>
												<li className="dropdown">
													<NavLink activeClassName="nav-link dropdown-toggle"
														to="/services" onClick={() => window.scrollTo(0, 0)} >
														Services
													</NavLink>
												</li>

												<li>
													<NavLink activeClassName="nav-link" to="/portfolio" onClick={() => window.scrollTo(0, 0)}>
														Portfolio
													</NavLink>
												</li>
												<li>
													<NavLink activeClassName="nav-link" to="/blog" onClick={() => window.scrollTo(0, 0)}>
														Blog
													</NavLink>
												</li>
												<li className="d-lg-none">
													<NavLink activeClassName="nav-link" to="/contact" onClick={handleCLickLink}>
														Contact
													</NavLink>
												</li>
											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
						<div
							className="header-column header-column-search justify-content-end align-items-center d-flex w-auto flex-row">
							<Link to="contact" onClick={() => window.scrollTo(0, 0)}
								className="btn btn-dark custom-btn-style-1 font-weight-semibold text-3-5 btn-px-3 py-2 ws-nowrap ms-4 d-none d-lg-block"
								data-cursor-effect-hover="plus" data-cursor-effect-hover-color="light"><span>Contact
									Us</span></Link>
							<div className="header-nav-features header-nav-features-no-border">
								<div className="header-nav-feature header-nav-features-search d-inline-flex">
									<a href="#" className="header-nav-features-toggle text-decoration-none"
										data-focus="headerSearch" aria-label="Search">
										<i
											className="icons icon-magnifier header-nav-top-icon text-3-5 text-color-dark text-color-hover-primary font-weight-semibold top-3"></i>
									</a>
									<div className="header-nav-features-dropdown header-nav-features-dropdown-mobile-fixed border-radius-0"
										id="headerTopSearchDropdown">
										<form role="search"
											action="https://www.okler.net/previews/porto/9.9.3/page-search-results.html"
											method="get">
											<div className="simple-search input-group">
												<input className="form-control text-1" id="headerSearch" name="q"
													type="search" value={q} placeholder="Search..."
													onChange={e => setQ(e.target.value)}
												/>
												<button className="btn" type="submit" aria-label="Search">
													<i
														className="icons icon-magnifier header-nav-top-icon text-color-dark text-color-hover-primary top-2"></i>
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
							<button className="btn header-btn-collapse-nav" data-bs-toggle="collapse"
								data-bs-target=".header-nav-main nav">
								<i className="fas fa-bars"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
		</header>
	</>)
}

export default Header