import React, { useRef } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './OwlTempo10css.css'


const OwlTempo10 = () => {

    const carouselRef = useRef();

    const handlePrev = () => {
        carouselRef.current.prev();

    }

    const handleNext = () => {
        carouselRef.current.next();
    }


    return (


        <>


            <section className="section section-height-3 border-0 m-0 lazyloaded"
                data-bg-src="assets/img/demos/business-consulting-3/backgrounds/background-3.jpg"
                style={{ backgroundImage: 'url("assets/img/demos/business-consulting-3/backgrounds/background-3.jpg")' }}>

                <div className="container py-4">

                    <div className="row">


                        <div className="col text-center">


                            <h2 className="custom-highlight-text-1 d-inline-block line-height-5 text-4 positive-ls-3 font-weight-medium text-color-primary mb-2 appear-animation animated fadeInUpShorter appear-animation-visible"
                                data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250"
                                style={{ animationDelay: '250ms' }}>TESTIMONIALS
                            </h2>

                            <h3 className="text-9 line-height-3 text-transform-none font-weight-semibold text-color-dark pb-3 mb-4 appear-animation animated fadeInUpShorter appear-animation-visible"
                                data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500"
                                style={{ animationDelay: '500ms' }}>What People
                                Say</h3>



                        </div>
                    </div>

                    <div className="row justify-content-center">

                        <div className="col-lg-11 appear-animation animated fadeInUpShorter appear-animation-visible"
                            data-appear-animation="fadeInUpShorter" data-appear-animation-delay="750"
                            style={{ animationDelay: '750ms' }}>



                            <OwlCarousel className='media21 owl-carousel owl-theme custom-nav-style-1 nav-style-1 nav-svg-arrows-1 nav-outside custom-dots-style-2 bg-light box-shadow-4 mb-0 owl-carousel-init owl-loaded owl-drag'
                                ref={carouselRef}
                                // data-plugin-options="{'responsive': {'0': {'items': 1, 'dots': true}, '768': {'items': 1}, '992': {'items': 1, 'nav': true, 'dots': false}, '1200': {'items': 1, 'nav': true, 'dots': false}}, 'loop': false, 'autoHeight': true}"
                             responsive={{
                                0:{items:1},
                                768:{items:1},
                                992:{items:1},
                                1200:{items:1}
                             }}
                                items={1} nav={true} loop={false} dots={true} navText={['<', '>']}
                                style={{ height: 'auto' }}>







                                {/* <div className="owl-stage-outer owl-height" style={{height: '385.438px'}}> */}

                                {/* <div className="owl-stage" style={{transform: 'translate3d(0px, 0px, 0px)', transition: 'all 0.25s ease 0s', width: '4084px'}}> */}

                                <div className="item" style={{ width: '1021px'}}>
                                    <div className="media21 py-5 px-lg-5">
                                        <div className="testimonial testimonial-style-2 px-4 mx-xl-5 my-3">

                                            <img width="40" height="40" src="assets/img/demos/business-consulting-3/icons/left-quote.svg" alt=""
                                                data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
                                                style={{ width: '40px', opacity: '1'}} className="mediaImg svg-fill-color-primary" />

                                            <blockquote>
                                                <p className="media21-text text-color-dark text-4 line-height-8 alternative-font-4 mb-0">
                                                    The Hajj Committee of India website is a vital resource for all
                                                    pilgrims. It provides comprehensive information, updates, and guidance,
                                                    simplifying the sacred journey of Hajj. This user-friendly platform
                                                    reflects the committee's dedication to serving the Muslim community. A
                                                    valuable online companion for a spiritually significant experience.</p>
                                            </blockquote>
                                            <div className="testimonial-author">
                                                <p>
                                                    <strong className="font-weight-bold text-5-5 negative-ls-1">- Hajj Committee
                                                        of India</strong>
                                                </p>
                                                <p className="text-color-dark mb-0">Dualsysco R&amp;D Client</p>
                                                <p></p>
                                            </div>

                                        </div>
                                    </div>
                                </div>



                                <div className="item" style={{ width: '1021px' }}>

                                    <div className="media21 py-5 px-lg-5">


                                        <div className="testimonial testimonial-style-2 px-4 mx-xl-5 my-3">


                                            <img width="40" height="40" src="assets/img/demos/business-consulting-3/icons/left-quote.svg" alt=""
                                                data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
                                                style={{ width: '40px', opacity: '1' }} className="svg-fill-color-primary" />
                                            <blockquote>
                                                <p className="media21-text text-color-dark text-4 line-height-8 alternative-font-4 mb-0">
                                                    La-Ribaa Banking Solution's website is a game-changer for ethical
                                                    finance. It offers a clear, accessible, and secure platform for
                                                    Sharia-compliant banking services. The website's design and
                                                    functionality align perfectly with the principles of Islamic finance,
                                                    making it an essential resource for conscientious investors and
                                                    customers. A commendable initiative!</p>
                                            </blockquote>

                                            <div className="testimonial-author">

                                                <p>
                                                    <strong className="font-weight-bold text-5-5 negative-ls-1">-
                                                        La-Ribaa</strong>
                                                </p>
                                                <p className="text-color-dark mb-0">Dualsysco R&amp;D Client</p>

                                                <p></p>



                                            </div>
                                        </div>

                                    </div>

                                </div>



                                <div className="item" style={{ width: '1021px' }}>
                                    <div className="media21 py-5 px-lg-5">
                                        <div className="testimonial testimonial-style-2 px-4 mx-xl-5 my-3">
                                            <img width="40" height="40" src="assets/img/demos/business-consulting-3/icons/left-quote.svg" alt=""
                                                data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
                                                style={{ width: '40px', opacity: '1' }} className="svg-fill-color-primary" />
                                            <blockquote>
                                                <p className="media21-text text-color-dark text-4 line-height-8 alternative-font-4 mb-0">
                                                    Arhan Gold &amp; Diamond's digital shopping website is a true treasure trove
                                                    for jewelry enthusiasts. It offers a splendid array of exquisite pieces,
                                                    and the user-friendly interface makes browsing and purchasing a breeze.
                                                    The secure transactions and prompt delivery make it the go-to
                                                    destination for all things precious. A sparkling online gem!</p>
                                            </blockquote>

                                            <div className="testimonial-author">
                                                <p>
                                                    <strong className="font-weight-bold text-5-5 negative-ls-1">- Arhan Gold &amp; Diamond's</strong>
                                                </p><p className="text-color-dark mb-0">Dualsysco R&amp;D Client</p>
                                                <p></p>
                                            </div>

                                        </div>

                                    </div>

                                </div>



                                <div className="item" style={{ width: '1021px' }}>
                                    <div className="media21 py-5 px-lg-5">
                                        <div className="testimonial testimonial-style-2 px-4 mx-xl-5 my-3">
                                            <img width="40" height="40" src="assets/img/demos/business-consulting-3/icons/left-quote.svg" alt="" data-icon=""
                                                data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
                                                style={{ width: '40px', opacity: '1' }} className="svg-fill-color-primary" />
                                            <blockquote>
                                                <p className="media21-text text-color-dark text-4 line-height-8 alternative-font-4 mb-0">
                                                    The Mohammed bin Zayed University website is a seamless gateway to
                                                    academic excellence. It's user-friendly, informative, and visually
                                                    captivating. Navigating through resources, news, and events has never
                                                    been easier. A testament to the institution's commitment to innovation
                                                    and accessibility. Kudos to the web development team for this remarkable
                                                    platform!</p>
                                            </blockquote>

                                            <div className="testimonial-author">
                                                <p>
                                                    <strong className="font-weight-bold text-5-5 negative-ls-1">- Mohammed bin
                                                        Zayed University</strong>
                                                </p><p className="text-color-dark mb-0">Dualsysco R&amp;D Client</p>
                                                <p></p>
                                            </div>




                                        </div>

                                    </div>

                                </div>





                                {/* </div> */}
                                {/* </div> */}

                            </OwlCarousel>



    



                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default OwlTempo10