import React from 'react'
import { useState,useRef } from 'react';
import { Link } from 'react-router-dom';
import './Portfolio.css'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";


const Portfolio = () => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [isExpanded1, setIsExpanded1] = useState(false);
	const [isExpanded2, setIsExpanded2] = useState(false);
	const [isExpanded3, setIsExpanded3] = useState(false);
	const [isExpanded4, setIsExpanded4] = useState(false);
	
	const toggleExpand = (event) => {
		event.preventDefault();
		setIsExpanded(!isExpanded);
	  };

	  const toggleExpand1 = (event) => {
		event.preventDefault();
		setIsExpanded1(!isExpanded1);
	  };
	  const toggleExpand2 = (event) => {
		event.preventDefault();
		setIsExpanded2(!isExpanded2);
	  };
	  const toggleExpand3 = (event) => {
		event.preventDefault();
		setIsExpanded3(!isExpanded3);
	  };

	  const toggleExpand4 = (event) => {
		event.preventDefault();
		setIsExpanded4(!isExpanded4);
	  };
	
function handleCLickLink(){
	window.scrollTo(0,0);
}

	return (<>


		<div data-plugin-cursor-effect>
			
			<div className="body">
				


				<div role="main" className="main">
				

					<section
						className="section section-with-shape-divider page-header page-header-modern page-header-lg border-0 my-0 lazyload"
						data-bg-src="assets/img/demos/business-consulting-3/backgrounds/background-5.jpg"
						style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}>
						

						<div className="container pb-5 my-3">
							
						{/* <AnimationOnScroll animateIn="animate__bounceIn"> */}
							<div className="row mb-4">
								
								<div className="col-md-12 align-self-center p-static order-2 text-center">
								
									<h1 className="animate__animated  font-weight-bold text-color-dark text-10">Portfolio</h1>
								</div>
								<div className="col-md-12 align-self-center order-1">
									<ul className="breadcrumb d-block text-center">
										<li><Link to="/" onClick={handleCLickLink} style={{textDecoration:'none',color:'#F04C23'}}>Home</Link></li>
										<li className="active">Portfolio</li>
									</ul>
								</div>
							</div>
							{/* </AnimationOnScroll> */}

						</div>
						<div className="shape-divider shape-divider-bottom shape-divider-reverse-x" /* style={{height: '123px'}} */>

							
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
								x="0px" y="0px" viewBox="0 0 1920 123" preserveAspectRatio="xMinYMin">
								<polygon fill="#F3F3F3" points="0,90 221,60 563,88 931,35 1408,93 1920,41 1920,-1 0,-1 " />
								<polygon fill="#FFFFFF" points="0,75 219,44 563,72 930,19 1408,77 1920,25 1920,-1 0,-1 " />
							</svg>
						</div>
					</section>

					<div className="container pt-3 pb-5 mt-4 mb-5">
					
						<div className="row">
							

							<div className="col text-center">
								
								<h2 className="text-9 text-lg-5 text-xl-9 line-height-3 text-transform-none font-weight-semibold mb-4 mb-lg-3 mb-xl-4 appear-animation animated fadeInUpShorter appear-animation-visible"
									data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250"
									style={{ animationDelay: '250ms' }}>
									IT Excellence:
									Spotlight on Our Top Projects</h2>
								<p className="text-3-5 mb-5 appear-animation animated fadeInUpShorter appear-animation-visible"
									data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500"
									style={{ animationDelay: '500ms' }}>
									Step into a curated selection of our most outstanding IT
									projects, where we exemplify excellence, innovation, and precision in every digital
									undertaking. Explore how our commitment to technological prowess shines through in every
									endeavor, making a difference in the digital landscape. </p>
							</div>
						</div>

						<div id="john" className="row mb-5 appear-animation animated fadeInUpShorter appear-animation-visible"
							data-appear-animation="fadeInUpShorter" data-appear-animation-delay="750"
							style={{ animationDelay: '750ms' }}>

							

							<div className="col">
								
								<div className="d-flex flex-wrap bg-light box-shadow-4 custom-link-hover-effects">
									
									<div className="position-relative col-12 col-md-4 lazyloaded" data-bg-src="assets/img/demos/business-consulting-3/team/team-1.png"
										style={{ backgroundPosition: 'center center', backgroundSize: 'cover', minHeight: '320px', backgroundImage: 'url("assets/img/demos/business-consulting-3/team/team-1.png")' }}>

										
										<div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
											
											<div className="custom-crooked-line">
											
												<img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
													alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
													style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />

											</div>
										</div>
									</div>
									<div className="col-md-8 p-5">
										<div className="d-flex justify-content-between justify-content-md-start mb-4">
											<div>
												<h2 className="font-weight-semibold text-6 line-height-1 mb-0">Arhan G&D</h2>
												<p className="text-3-5 mb-0">Digital Shopping</p>
											</div>
											<div className=" bg-color-grey ms-4 me-1 d-none d-md-block" style={{ width: '8px' }}></div>
											<ul
												className="arhan-social social-icons social-icons-clean social-icons-icon-dark social-icons-big m-0 ms-lg-2">
												<li className="social-icons-instagram">
													<a href="http://www.instagram.com/" target="_blank" className="text-4"
														title="Instagram" data-cursor-effect-hover="fit"><i
															className="fab fa-instagram"></i></a>
												</li>
												<li className="social-icons-linkedin">
													<a href="http://www.linkedin.com/" target="_blank" className="text-4"
														title="Twitter" data-cursor-effect-hover="fit"><i
															className="fab fa-linkedin-in"></i></a>
												</li>
												<li className="social-icons-facebook">
													<a href="http://www.facebook.com/" target="_blank" className="text-4"
														title="Facebook" data-cursor-effect-hover="fit"><i
															className="fab fa-facebook-f"></i></a>
												</li>
											</ul>
										</div>



										<div className="arhan custom-read-more-style-1 position-relative"  style={{ height: isExpanded ? 'auto' : '150px', overflowY: 'hidden' }}>
      <p className="text-3-5">Elevate your jewelry shopping experience with the Arhan Gold &amp;
												Diamonds Digital Shopping App. We bring the world of opulence and elegance right
												to your fingertips. Discover an extensive range of exquisite jewelry, from
												intricate gold pieces that embody timeless beauty to stunning diamonds that
												sparkle with unparalleled brilliance.</p>
      <p className="text-3-5">Our app provides a secure and convenient platform for you to
												explore and select the perfect adornments for every occasion. Whether you're
												seeking a timeless piece to mark a special moment or a stunning gift for a loved
												one, Arhan Gold &amp; Diamonds has you covered. With user-friendly navigation and a
												seamless shopping experience, our app ensures that you can indulge in the luxury
												of fine jewelry shopping with ease. Elevate your style and celebrate life's
												precious moments with the Arhan Gold &amp; Diamonds Digital Shopping App today.</p>

      <div className="arhan readmore-button-wrapper text-start" style={{ position: 'absolute', bottom: '0px', left: '0px', width: '100%', zIndex: '2' }}>
        <a href="#" className="text-decoration-none" onClick={toggleExpand} >
          {isExpanded ? 'View Less ' : 'View More '}
          <i className={`fas fa-chevron-${isExpanded ? 'up' : 'down'} text-2 ms-1`}  />
        </a>
      </div>

     {/*  {isExpanded && (
        <div className="readmore-overlay" style={{ background: 'linear-gradient(rgba(2, 0, 36, 0) 0%, rgb(255, 255, 255) 100%)', position: 'absolute', bottom: '0px', left: '0px', width: '100%', height: '200px', zIndex: '1' }}></div>
      )} */}
    </div>



										

									</div>
								</div>
							</div>
						</div>


						<div id="robert" className="row mb-5 appear-animation animated fadeInUpShorter appear-animation-visible"
							data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250"
							style={{ animationDelay: '250ms' }}>

							<div className="col">
								<div className="d-flex flex-wrap bg-light box-shadow-4 custom-link-hover-effects">
									<div className="position-relative col-12 col-md-4 lazyloaded" data-bg-src="assets/img/demos/business-consulting-3/team/team-2.png"
										style={{ backgroundPosition: 'center center', backgroundSize: 'cover', minHeight: '320px', backgroundImage: 'url("assets/img/demos/business-consulting-3/team/team-2.png")' }}>

										<div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">

											<div className="custom-crooked-line">
												<img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
													alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
													style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />
											</div>
										</div>
									</div>
									<div className="col-md-8 p-5">

										<div className="d-flex justify-content-between justify-content-md-start mb-4">
											<div>
												<h2 className="font-weight-semibold text-6 line-height-1 mb-0">E-Muallim</h2>
												<p className="text-3-5 mb-0">study material</p>
											</div>
											<div className="bg-color-grey ms-4 me-1 d-none d-md-block"
												style={{ width: '8px' }}></div>
											<ul className="e-muallim-social social-icons social-icons-clean social-icons-icon-dark social-icons-big m-0 ms-lg-2">

												<li className="social-icons-instagram">
													<a href="http://www.instagram.com/" target="_blank" className="text-4" title="Instagram" data-cursor-effect-hover="fit">
														<i className="fab fa-instagram">
														</i></a>
												</li>
												<li className="social-icons-linkedin">
													<a href="http://www.linkedin.com/" target="_blank" className="text-4" title="Twitter" data-cursor-effect-hover="fit">
														<i className="fab fa-linkedin-in">
														</i></a>
												</li>
												<li className="social-icons-facebook">
													<a href="http://www.facebook.com/" target="_blank" className="text-4" title="Facebook" data-cursor-effect-hover="fit">
														<i className="fab fa-facebook-f">
														</i></a>
												</li>
											</ul>
										</div>


										<div className="custom-read-more-style-1 position-relative"  style={{ height: isExpanded1 ? 'auto' : '175px', overflowY: 'hidden' }}>
      <p className="text-3-5">The E-Muallim Study Material App is your dedicated companion on the path to academic success. Designed to empower learners of all ages, this comprehensive app offers a vast repository of educational resources, ensuring you have access to high-quality study materials whenever you need them. From textbooks and reference materials to interactive quizzes and video tutorials, E-Muallim has it all, covering a wide range of subjects and grade levels.</p>
      <p className="text-3-5">Our user-friendly interface makes navigation a breeze, 
	  allowing you to easily search for specific topics, access course materials, and track your progress.
	   Whether you're a student preparing for exams, a teacher looking for supplementary resources, 
	   or a lifelong learner eager to expand your knowledge, E-Muallim is your one-stop solution for
	    enriching your educational journey. With the E-Muallim Study Material App, you have the tools to
		 excel in your studies and achieve your academic goals with confidence.</p>

      <div  className="e-muallim readmore-button-wrapper  text-start "   style={{ position: 'absolute' ,bottom: '-0px', left: '0px', width: '100%', zIndex: '2' }}>
        <a href="#" className="text-decoration-none" onClick={toggleExpand1}>
          {isExpanded1 ? 'View Less ' : 'View More '}
          <i className={`fas fa-chevron-${isExpanded1 ? 'up' : 'down'} text-2 ms-1`} />
        </a>
      </div>

        {isExpanded1 && (
        <div /* className="readmore-overlay" */  style={{ /* background: 'linear-gradient(rgba(2, 0, 36, 0) 0%, rgb(255, 255, 255) 100%)', */  position: 'absolute', bottom: '0px', left: '0px', width: '100%', height: '160px', zIndex: '1' }}>

		</div>
      )} 
    </div>
										

									</div>
								</div>
							</div>
						</div>

						<div id="jessica" className="row mb-5 appear-animation animated fadeInUpShorter appear-animation-visible"
							data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250"
							style={{ animationDelay: '250ms' }}>



							<div className="col">

								<div className="d-flex flex-wrap bg-light box-shadow-4 custom-link-hover-effects">

									<div className="position-relative col-12 col-md-4 lazyloaded"
										data-bg-src="assets/img/demos/business-consulting-3/team/team-3.png"
										style={{
											backgroundPosition: 'center center', backgroundSize: 'cover', minHeight: '320px',
											backgroundImage: 'url("assets/img/demos/business-consulting-3/team/team-3.png")'
										}}>

										
										<div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
											<div className="custom-crooked-line">
												<img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
													alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
													style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />

											</div>
										</div>
									</div>
									<div className="col-md-8 p-5">
										<div className="d-flex justify-content-between justify-content-md-start mb-4">
											<div>
												<h2 className="font-weight-semibold text-6 line-height-1 mb-0">Nippen</h2>
												<p className="text-3-5 mb-0">Electrical Instrument Co.</p>
											</div>
											<div className="bg-color-grey ms-4 me-1 d-none d-md-block" style={{ width: '8px' }}></div>
											<ul
												className="nippen-social social-icons social-icons-clean social-icons-icon-dark social-icons-big m-0 ms-lg-2">
												<li className="social-icons-instagram">
													<a href="http://www.instagram.com/" target="_blank" className="text-4"
														title="Instagram" data-cursor-effect-hover="fit"><i
															className="fab fa-instagram"></i></a>
												</li>
												<li className="social-icons-linkedin">
													<a href="http://www.linkedin.com/" target="_blank" className="text-4"
														title="Twitter" data-cursor-effect-hover="fit"><i
															className="fab fa-linkedin-in"></i></a>
												</li>
												<li className="social-icons-facebook">
													<a href="http://www.facebook.com/" target="_blank" className="text-4"
														title="Facebook" data-cursor-effect-hover="fit"><i
															className="fab fa-facebook-f"></i></a>
												</li>
											</ul>
										</div>


										<div className="custom-read-more-style-1 position-relative"  style={{ height: isExpanded2 ? 'auto' : '230px', overflowY: 'hidden' }}>
      <p className="text-3-5">The Nippen Electrical Instrument App is your indispensable
												companion for all things related to electrical measurements and instrumentation.
												Packed with a comprehensive suite of tools and resources, this app is tailored
												for both professionals and enthusiasts in the field of electrical engineering.
												From digital multimeters and oscilloscopes to power analyzers and insulation
												testers, Nippen's app provides easy access to a wide range of high-quality
												electrical instruments, making it a go-to resource for accurate measurements and
												diagnostics.</p>
      <p className="text-3-5">Navigating through the app is intuitive, ensuring you can
												quickly find the right tool for your needs. Additionally, the app offers
												valuable insights, tutorials, and documentation to assist users in understanding
												and effectively utilizing these instruments. Whether you're a seasoned
												electrical engineer, a technician, or simply interested in learning more about
												electrical measurements, the Nippen Electrical Instrument App is your trusted
												resource for precise, reliable, and efficient electrical testing and analysis.
												Upgrade your electrical measurement capabilities with the convenience and
												expertise of Nippen, right at your fingertips.</p>

      <div className="nippen readmore-button-wrapper text-start" style={{ position: 'absolute', bottom: '0px', left: '0px', width: '100%', zIndex: '2' }}>
        <a href="#" className="text-decoration-none" onClick={toggleExpand2}>
          {isExpanded2 ? 'View Less ' : 'View More '}
          <i className={`fas fa-chevron-${isExpanded2 ? 'up' : 'down'} text-2 ms-1`} />
        </a>
      </div>

      {/* {isExpanded2 && (
        <div className="readmore-overlay" style={{ background: 'linear-gradient(rgba(2, 0, 36, 0) 0%, rgb(255, 255, 255) 100%)', position: 'absolute', bottom: '0px', left: '0px', width: '100%', height: '100px', zIndex: '1' }}></div>
      )} */}
    </div>


										

									</div>
								</div>
							</div>
						</div>


						

						<div id="monica" className="row mb-5 appear-animation animated fadeInUpShorter appear-animation-visible"
							data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250"
							style={{ animationDelay: '250ms' }}>

							
							<div className="col">
								<div className="d-flex flex-wrap bg-light box-shadow-4 custom-link-hover-effects">
									<div className="position-relative col-12 col-md-4 lazyloaded"
										data-bg-src="assets/img/demos/business-consulting-3/team/team-4.png"
										style={{ backgroundPosition: 'center center', backgroundSize: 'cover', minHeight: '320px', backgroundImage: 'url("assets/img/demos/business-consulting-3/team/team-4.png")' }}>

										<div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
											<div className="custom-crooked-line">
												<img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
													alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
													style={{ width: '154px' }} />
											</div>
										</div>
									</div>
									<div className="col-md-8 p-5">
										<div className="d-flex justify-content-between justify-content-md-start mb-4">
											<div>
												<h2 className="font-weight-semibold text-6 line-height-1 mb-0">La-Ribaa</h2>
												<p className="text-3-5 mb-0">Banking Solutions</p>
											</div>
											<div className="bg-color-grey ms-4 me-1 d-none d-md-block"
												style={{ width: '8px' }}>

											</div>
											<ul className="la-ribba-social social-icons social-icons-clean social-icons-icon-dark social-icons-big m-0 ms-lg-2">
												<li className="social-icons-instagram">
													<a href="http://www.instagram.com/" target="_blank" className="text-4" title="Instagram"
														data-cursor-effect-hover="fit">
														<i className="fab fa-instagram">
														</i></a>
												</li>
												<li className="social-icons-linkedin">
													<a href="http://www.linkedin.com/" target="_blank" className="text-4"
														title="Twitter" data-cursor-effect-hover="fit">
														<i className="fab fa-linkedin-in">
														</i></a>
												</li>
												<li className="social-icons-facebook">
													<a href="http://www.facebook.com/" target="_blank" className="text-4"
														title="Facebook" data-cursor-effect-hover="fit">
														<i className="fab fa-facebook-f">
														</i></a>
												</li>
											</ul>
										</div>

										<div className="custom-read-more-style-1 position-relative" style={{ height: isExpanded3 ? 'auto' : '200px', overflowY: 'hidden' }}>
      <p className="text-3-5">The La-Ribaa Banking Solutions App is your gateway to modern,
												ethical, and Sharia-compliant banking services. We've revolutionized the banking
												experience, offering a comprehensive suite of financial tools and services that
												adhere to Islamic principles. From interest-free savings accounts to transparent
												investment opportunities, our app empowers individuals and businesses to manage
												their finances while maintaining their commitment to ethical and
												Sharia-compliant banking practices.</p>
      <p className="text-3-5">With a user-friendly interface and secure features, the La-Ribaa
												Banking Solutions App simplifies your financial transactions and investments. It
												also provides valuable resources and educational materials to help users make
												informed financial decisions in line with Islamic finance principles. Whether
												you're seeking to save, invest, or access a range of ethical banking services,
												our app offers a seamless and responsible banking experience. Experience the
												future of Islamic banking with La-Ribaa, where your financial well-being aligns
												with your values.</p>

      <div className="la-ribba readmore-button-wrapper text-start" style={{ position: 'absolute', bottom: '0px', left: '0px', width: '100%', zIndex: '2' }}>
        <a href="#" className="text-decoration-none" onClick={toggleExpand3}>
          {isExpanded3 ? 'View Less ' : 'View More '}
          <i className={`fas fa-chevron-${isExpanded3 ? 'up' : 'down'} text-2 ms-1`} />
        </a>
      </div>

      {/* {isExpanded3 && (
        <div className="readmore-overlay" style={{ background: 'linear-gradient(rgba(2, 0, 36, 0) 0%, rgb(255, 255, 255) 100%)', position: 'absolute', bottom: '0px', left: '0px', width: '100%', height: '100px', zIndex: '1' }}></div>
      )} */}
    </div>




										

									</div>
								</div>
							</div>
						</div>



						<div id="chris" className="row appear-animation animated fadeInUpShorter appear-animation-visible"
							data-appear-animation="fadeInUpShorter"
							data-appear-animation-delay="250"
							style={{ animationDelay: '250ms' }}>

							<div className="col">

								<div className="d-flex flex-wrap bg-light box-shadow-4 custom-link-hover-effects">

									<div className="position-relative col-12 col-md-4 lazyloaded"
										data-bg-src="assets/img/demos/business-consulting-3/team/team-5.png"
										style={{
											backgroundPosition: 'center center', backgroundSize: 'cover', minHeight: '320px',
											backgroundImage: 'url("assets/img/demos/business-consulting-3/team/team-5.png")'
										}}>
										<div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
											<div className="custom-crooked-line">
												<img width="154" height="26"
													src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg" alt=""
													data-icon
													data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
													style={{ width: '154px' }} />
											</div>
										</div>
									</div>
									<div className="col-md-8 p-5">
										<div className="d-flex justify-content-between justify-content-md-start mb-4">
											<div>
												<h2 className="font-weight-semibold text-6 line-height-1 mb-0">Campus Connekt</h2>
												<p className="text-3-5 mb-0">School Management</p>
											</div>
											<div className="bg-color-grey ms-4 me-1 d-none d-md-block" style={{ width: '8px' }}></div>
											<ul
												className="campus-social social-icons social-icons-clean social-icons-icon-dark social-icons-big m-0 ms-lg-2">
												<li className="social-icons-instagram">
													<a href="http://www.instagram.com/" target="_blank" className="text-4"
														title="Instagram" data-cursor-effect-hover="fit"><i
															className="fab fa-instagram"></i></a>
												</li>
												<li className="social-icons-twitter">
													<a href="http://www.linkedin.com/" target="_blank" className="text-4"
														title="linkedin" data-cursor-effect-hover="fit"><i
															className="fab fa-linkedin-in"></i></a>
												</li>
												<li className="social-icons-facebook">
													<a href="http://www.facebook.com/" target="_blank" className="text-4"
														title="Facebook" data-cursor-effect-hover="fit"><i
															className="fab fa-facebook-f"></i></a>
												</li>
											</ul>
										</div>



										<div className="custom-read-more-style-1 position-relative"  style={{ height: isExpanded4 ? 'auto' : '230px', overflowY: 'hidden' }}>
      <p className="text-3-5">The Campus Connekt School Management App is the ultimate
												solution for educational institutions looking to streamline their operations and
												enhance communication within the campus community. This comprehensive app
												simplifies administrative tasks, empowers educators, and keeps parents and
												students engaged in the learning process. From attendance tracking and grade
												management to communication tools that facilitate real-time updates and
												announcements, Campus Connekt is designed to meet the diverse needs of schools
												and colleges.</p>
      <p className="text-3-5">Our user-friendly interface ensures that educators and
												administrators can efficiently manage their daily tasks, while parents and
												students can stay informed about academic progress, events, and important
												announcements. With features like homework assignments, event calendars, and
												secure messaging, Campus Connekt fosters a more connected and efficient
												educational environment. Elevate your school's management and communication with
												the Campus Connekt School Management App, where technology meets education to
												create a seamless learning experience for all stakeholders.</p>

      <div className="campus readmore-button-wrapper text-start" style={{ position: 'absolute', bottom: '0px', left: '0px', width: '100%', zIndex: '2' }}>
        <a href="#" className="text-decoration-none" onClick={toggleExpand4}>
          {isExpanded4 ? 'View Less ' : 'View More '}
          <i className={`fas fa-chevron-${isExpanded4 ? 'up' : 'down'} text-2 ms-1`} />
        </a>
      </div>

      {/* {isExpanded4 && (
        <div className="readmore-overlay" style={{ background: 'linear-gradient(rgba(2, 0, 36, 0) 0%, rgb(255, 255, 255) 100%)', position: 'absolute', bottom: '0px', left: '0px', width: '100%', height: '100px', zIndex: '1' }}></div>
      )} */}
    </div>



										


									</div>
								</div>
							</div>
						</div>
					</div>

				</div>


			</div>




			{/* <!-- Vendor --> */}
			<script data-cfasync="false" src="../../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script>
			<script src="assets/vendor/plugins/js/plugins.min.js"></script>

			{/* <!-- Theme Base, Components and Settings --> */}
			<script src="assets/js/theme.js"></script>

			{/* 	<!-- Current Page Vendor and Views --> */}
			<script src="assets/js/views/view.contact.js"></script>

			{/* <!-- Theme Initialization Files --> */}
			<script src="assets/js/theme.init.js"></script>

			<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAhpYHdYRY2U6V_VfyyNtkPHhywLjDkhfg"></script>



		</div>

		
	</>)
}

export default Portfolio