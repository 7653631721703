import React, { useState } from 'react'
import OwlServices from '../components/OwlServices';
import { Link } from 'react-router-dom';


const Services = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');

	function handleCLickLink(){
		window.scrollTo (0,0);
	}

	return (
		<>
			<div data-plugin-cursor-effect>
				<div className="body">


					<div role="main" className="main">

						<section
							className="section section-with-shape-divider page-header page-header-modern page-header-lg border-0 my-0 lazyload"
							data-bg-src="assets/img/demos/business-consulting-3/backgrounds/background-5.jpg"
							style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}>
							<div className="container pb-5 my-3">
								<div className="row mb-4">
									<div className="col-md-12 align-self-center p-static order-2 text-center">
										<h1 className="font-weight-bold text-color-dark text-10">Services</h1>
									</div>
									<div className="col-md-12 align-self-center order-1">
										<ul className="breadcrumb d-block text-center">
											<li><Link to="/" onClick={handleCLickLink} style={{textDecoration:'none',color:'#F04C23'}}>Home</Link></li>
											<li className="active">Services</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="shape-divider shape-divider-bottom shape-divider-reverse-x" style={{ height: '123px' }}>
								<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
									x="0px" y="0px" viewBox="0 0 1920 123" preserveAspectRatio="xMinYMin">
									<polygon fill="#F3F3F3" points="0,90 221,60 563,88 931,35 1408,93 1920,41 1920,-1 0,-1 " />
									<polygon fill="#FFFFFF" points="0,75 219,44 563,72 930,19 1408,77 1920,25 1920,-1 0,-1 " />
								</svg>
							</div>
						</section>

						<section className="section section-height-4 section-with-shape-divider bg-color-grey-scale-1 border-0 pb-5 m-0">
							

							<div className="shape-divider" style={{ height: '123px' }}>
								
								<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									viewBox="0 0 1920 123" preserveAspectRatio="xMinYMin">
									<polygon fill="#F3F3F3" points="0,90 221,60 563,88 931,35 1408,93 1920,41 1920,-1 0,-1 "></polygon>
									<polygon fill="#FFFFFF" points="0,75 219,44 563,72 930,19 1408,77 1920,25 1920,-1 0,-1 "></polygon>


								</svg>
							</div>
							<div className="container mt-4">
								
								<div className="row justify-content-center">
									
									<div className="col-lg-11 col-xl-10 text-center">
										

										<h2 className="custom-highlight-text-1 d-inline-block line-height-5 text-4 positive-ls-3 font-weight-medium text-color-primary mb-2 appear-animation animated fadeInUpShorter appear-animation-visible"
											data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250"
											style={{ animationDelay: '250ms' }}>WHAT WE DO
										</h2>
										<h3 className="text-9 line-height-3 text-transform-none font-weight-semibold mb-3 pb-1 appear-animation animated fadeInUpShorter appear-animation-visible"
											data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500"
											style={{ animationDelay: '500ms' }}>Our Premium
											Services</h3>
										<p className="text-3-5 pb-3 mb-4 appear-animation animated fadeInUpShorter appear-animation-visible"
											data-appear-animation="fadeInUpShorter" data-appear-animation-delay="750"
											style={{ animationDelay: '750ms' }}>"Your trusted startup advisor, unlocking the power of
											AI, IoT, web and app development, and automation services to drive your success in the
											digital age."</p>

									</div>
								</div>

								<div className="row row-gutter-sm justify-content-center mb-5 appear-animation animated fadeInUpShorter appear-animation-visible"
									data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000"
									style={{ animationDelay: '1000ms' }}>

									

									<div className="col-sm-9 col-md-6 col-lg-4 mb-4">

										
										<Link to="./" onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none"
											data-cursor-effect-hover="plus">
											<div className="card box-shadow-4">
												<div className="card-img-top position-relative overlay overlay-show">
													<div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
														<h4 className="font-weight-semibold text-color-light text-6 mb-1">New StarUp
															Consultant
														</h4>
														<div className="custom-crooked-line">
															<img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
																alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
																style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />


														</div>

													</div>

													<img src="assets/img/demos/business-consulting-3/services/new star up.png"
														className="img-fluid" alt="Card Image" />



												</div>

												<div className="card-body d-flex align-items-center custom-view-more px-4">
													<p className="card-text w-100 mb-0">Navigating success for startups with expert
														guidance and tailored strategies. </p>
													<img width="50" height="50" className="w-auto svg-fill-color-primary"
														src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt=""
														data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
														style={{ width: '50px', opacity: '1' }} />



												</div>

											</div>


										</Link>

									</div>

									<div className="col-sm-9 col-md-6 col-lg-4 mb-4 mb-lg-0">
										<Link to="/services" onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none"
											data-cursor-effect-hover="plus">
											<div className="card box-shadow-4">
												<div className="card-img-top position-relative overlay overlay-show">
													<div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
														<h4 className="font-weight-semibold text-color-light text-6 mb-1">Artificial
															Intelligence </h4>
														<div className="custom-crooked-line">
															<img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
																alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
																style={{ width: '154p', opacity: '1' }} className="svg-fill-color-primary" />



														</div>


													</div>

													<img src="assets/img/demos/business-consulting-3/services/Art in.png"
														className="img-fluid" alt="Card Image" />




												</div>

												<div className="card-body d-flex align-items-center custom-view-more px-4">
													<p className="card-text w-100 mb-0">Artificial Intelligence to enhance your business
														operations, data analysis, and decision-making. </p>
													<img width="50" height="50" className="w-auto svg-fill-color-primary"
														src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt="" data-icon=""
														data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
														style={{ width: '50px', opacity: '1' }} />




												</div>

											</div>

										</Link>

									</div>

									<div className="col-sm-9 col-md-6 col-lg-4 mb-4 mb-lg-0">
										<Link to="/services" onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none"
											data-cursor-effect-hover="plus">
											<div className="card box-shadow-4">
												<div className="card-img-top position-relative overlay overlay-show">
													<div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
														<h4 className="font-weight-semibold text-color-light text-6 mb-1">Internet of
															things </h4>
														<div className="custom-crooked-line">
															<img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
																alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
																style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />


														</div>

													</div>

													<img src="assets/img/demos/business-consulting-3/services/IOT.png" className="img-fluid" alt="Card Image" />


												</div>
												<div className="card-body d-flex align-items-center custom-view-more px-4">
													<p className="card-text w-100 mb-0">IoT services provide connectivity and insights
														to optimize processes and enhance productivity. </p>
													<img width="50" height="50" className="w-auto svg-fill-color-primary"
														src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt="" data-icon=""
														data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
														style={{ width: '50px', opacity: '1' }} />

												</div>

											</div>
										</Link>

									</div>

									<div className="col-sm-9 col-md-6 col-lg-4 mb-4">
										<Link to="/services"  onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none"
											data-cursor-effect-hover="plus">
											<div className="card box-shadow-4">
												<div className="card-img-top position-relative overlay overlay-show">
													<div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
														<h4 className="font-weight-semibold text-color-light text-6 mb-1">Web
															Development</h4>
														<div className="custom-crooked-line">

															<img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
																alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
																style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />


														</div>

													</div>
													<img src="assets/img/demos/business-consulting-3/services/website development.png"
														className="img-fluid" alt="Card Image" />



												</div>
												<div className="card-body d-flex align-items-center custom-view-more px-4">
													<p className="card-text w-100 mb-0">Crafting engaging and user-centric websites to
														boost your online presence and drive digital success.</p>
													<img width="50" height="50" className="w-auto svg-fill-color-primary"
														src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt="" data-icon=""
														data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
														style={{ width: '50px', opacity: '1' }} />


												</div>

											</div>

										</Link>
									</div>

									
									<div className="col-sm-9 col-md-6 col-lg-4 mb-4">
										<Link to="/services" onClick={handleCLickLink} className="custom-link-hover-effects text-decoration-none"
											data-cursor-effect-hover="plus">
											<div className="card box-shadow-4">
												<div className="card-img-top position-relative overlay overlay-show">
													<div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
														<h4 className="font-weight-semibold text-color-light text-6 mb-1">App
															Development</h4>
														<div className="custom-crooked-line">
															<img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
																alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
																style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />
														</div>
													</div>
													<img src="assets/img/demos/business-consulting-3/services/android development.png"
														className="img-fluid" alt="Card Image" />
												</div>
												<div className="card-body d-flex align-items-center custom-view-more px-4">
													<p className="card-text w-100 mb-0">Transform your ideas into user-friendly,
														feature-rich mobile App for the Android platform. </p>
													<img width="50" height="50" className="w-auto svg-fill-color-primary"
														src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt="" data-icon=""
														data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
														style={{ width: '50px', opacity: '1' }} />
												</div>
											</div>
										</Link>
									</div>
								

									<div className="col-sm-9 col-md-6 col-lg-4">
										<Link to="/services" onClick={handleCLickLink}
											className="custom-link-hover-effects text-decoration-none" data-cursor-effect-hover="plus">
											<div className="card box-shadow-4">
												<div className="card-img-top position-relative overlay overlay-show">
													<div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
														<h4 className="font-weight-semibold text-color-light text-6 mb-1">Automation
														</h4>
														<div className="custom-crooked-line">
															<img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
																alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
																style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />
														</div>
													</div>
													<img src="assets/img/demos/business-consulting-3/services/automition.png"
														className="img-fluid" alt="Card Image" />
												</div>
												<div className="card-body d-flex align-items-center custom-view-more px-4">
													<p className="card-text w-100 mb-0">Revolutionizing your workflow with intelligent,
														time-saving processes and solutions. </p>
													<img width="50" height="50" className="w-auto svg-fill-color-primary"
														src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt="" data-icon=""
														data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
														style={{ width: '50px', opacity: '1' }} />
												</div>
											</div>
										</Link>
									</div>

								</div>

								<div className="row">
									<div className="col text-center">
										<Link to="/services" onClick={handleCLickLink} className="btn btn-primary custom-btn-style-1 font-weight-semibold btn-px-4 btn-py-2 text-3-5"
											data-cursor-effect-hover="plus" data-cursor-effect-hover-color="light">
											<span>All Services</span>
										</Link>
									</div>
								</div>


							</div>
						</section>



						
					</div>



					

					<section className="section section-with-shape-divider position-relative bg-dark border-0 m-0">
						<div className="shape-divider shape-divider-reverse-x z-index-3" style={{ height: '102px' }}>
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 102" preserveAspectRatio="xMinYMin">
								<polygon fill="#F3F3F3" points="0,65 220,35 562,63 931,10 1410,68 1920,16 1920,103 0,103 "></polygon>
								<polygon fill="#EDEDED" points="0,82 219,51 562,78 931,26 1409,83 1920,32 1920,103 0,103 "></polygon>
							</svg>
						</div>
						<div className="position-absolute top-0 right-0 overlay overlay-show overlay-color-primary overlay-op-9 h-100 d-none d-md-block lazyloaded" data-bg-src="assets/img/demos/business-consulting-3/backgrounds/background-2.jpg" style={{ width: '32%', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundImage: 'url(&quot;img/demos/business-consulting-3/backgrounds/background-2.jpg&quot;)' }}></div>
						<div className="container">
							<div className="row align-items-center pt-5 pb-xl-5 mt-5">
								<div className="col-md-7 col-lg-8 py-4 my-2 ms-md-3 ms-lg-0">
									<h2 className="custom-highlight-text-1 d-inline-block line-height-5 text-4 positive-ls-3 font-weight-medium text-color-primary mb-2 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250" style={{ animationDelay: '250ms' }}>GET IN TOUCH
									</h2>
									<h3 className="text-9 line-height-3 text-transform-none font-weight-medium text-color-light ls-0 mb-3 pb-1 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500" style={{ animationDelay: '500ms' }}>Send Us a
										Message and Learn More About Our Services</h3>
									<p className="text-3-5 pb-3 mb-4 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="750" style={{ animationDelay: '750ms' }}>Connect with us by sending a message to explore and
										gain deeper insights into our comprehensive range of services and offerings. </p>
									<form className="contact-form form-style-4 form-placeholders-light form-errors-light appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000" action="https://www.okler.net/previews/porto/9.9.3/php/contact-form.php" method="POST" noValidate="novalidate" style={{ animationDelay: '1000ms' }}>
										<div className="contact-form-success alert alert-success d-none mt-4">
											<strong>Error!</strong> There was an error sending your message.

										</div>

										<div className="contact-form-error alert alert-danger d-none mt-4">
											<strong>Success!</strong> Your message has been sent to us.

											<span className="mail-error-message text-1 d-block"></span>
										</div>

										<div className="row">
											<div className="form-group col">
												<input type="text" value={name} data-msg-required="Please enter your name." maxLength="100"
													className="form-control text-3 custom-border-color-grey-1 h-auto py-2 error" name="name"
													placeholder="* Full Name" required="" aria-invalid="true"
													onChange={e => setName(e.target.value)}
												/>
												{/* <label id="name-error" className="error" htmlFor="name" style={{}}>Please enter your name.</label> */}
											</div>
										</div>
										<div className="row">
											<div className="form-group col">
												<input type="email" value={email} data-msg-required="Please enter your email address." data-msg-email="Please enter a valid email address." maxLength="100"
													className="form-control text-3 custom-border-color-grey-1 h-auto py-2 error" name="email"
													placeholder="* Email Address" required="" aria-invalid="true"
													onChange={e => setEmail(e.target.value)}
												/>

												{/* <label id="email-error" className="error" htmlFor="email" style={{}}>Please enter your email address.</label> */}
											</div>

										</div>
										<div className="row mb-4">


											<div className="form-group col">
												<textarea maxLength="5000" data-msg-required="Please enter your message." rows="8" className="form-control text-3 custom-border-color-grey-1 h-auto py-2" name="message" placeholder="* Message">

												</textarea>
											</div>

										</div>
										<div className="row">
											<div className="form-group col appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1250"
												style={{ animationDelay: '1250ms' }}>
												<button type="submit" className="btn btn-primary custom-btn-style-1 font-weight-semibold btn-px-4 btn-py-2 text-3-5" data-loading-text="Loading..." data-cursor-effect-hover="plus" data-cursor-effect-hover-color="light">
													<span>Send Message</span>
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</section>






					<section className="section section-height-3 border-0 m-0 lazyloaded"
						data-bg-src="assets/img/demos/business-consulting-3/backgrounds/background-3.jpg"
						style={{ backgroundImage: 'url("assets/img/demos/business-consulting-3/backgrounds/background-3.jpg")' }}>


						<div className="container py-4">

							<div className="row">


								<div className="col text-center">


									<h2 className="custom-highlight-text-1 d-inline-block line-height-5 text-4 positive-ls-3 font-weight-medium text-color-primary mb-2 appear-animation animated fadeInUpShorter appear-animation-visible"
										data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250"
										style={{ animationDelay: '250ms' }}>TESTIMONIALS
									</h2>

									<h3 className="text-9 line-height-3 text-transform-none font-weight-semibold text-color-dark pb-3 mb-4 appear-animation animated fadeInUpShorter appear-animation-visible"
										data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500"
										style={{ animationDelay: '500ms' }}>What People
										Say</h3>



								</div>
							</div>

							<div className="row justify-content-center">

								<div className="col-lg-11 appear-animation animated fadeInUpShorter appear-animation-visible"
									data-appear-animation="fadeInUpShorter" data-appear-animation-delay="750"
									style={{ animationDelay: '750ms' }}>





									<OwlServices />

								</div>
							</div>
						</div>


					</section>

				</div>

			</div>




			{/* <!-- Vendor --> */}
			<script data-cfasync="false" src="../../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script>
			<script src="assets/vendor/plugins/js/plugins.min.js"></script>

			{/* <!-- Theme Base, Components and Settings --> */}
			<script src="assets/js/theme.js"></script>

			{/* <!-- Current Page Vendor and Views --> */}
			<script src="assets/js/views/view.contact.js"></script>

			{/* <!-- Theme Initialization Files --> */}
			<script src="assets/js/theme.init.js"></script>

			<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAhpYHdYRY2U6V_VfyyNtkPHhywLjDkhfg"></script>





		</>
	)
}

export default Services