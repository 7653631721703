import React, { useState,useRef } from 'react'
import { Link } from 'react-router-dom';
import './Blog.css'


const Blog = () => {
	const [isExpanded1, setIsExpanded1] = useState(false);
	const contentRef1 = useRef();
	const [isExpanded2, setIsExpanded2] = useState(false);
	const [isExpanded3, setIsExpanded3] = useState(false);

  
	const toggleExpand1 = (event) => {
	  event.preventDefault();
	  setIsExpanded1(!isExpanded1);
	  if (!isExpanded1) {
		contentRef1.current.scrollIntoView({ behavior: 'smooth' });
	  }
	};
  
	const toggleExpand2 = (event) => {
	  event.preventDefault();
	  setIsExpanded2(!isExpanded2);
	};

	const toggleExpand3 = (event) => {
		event.preventDefault();
		setIsExpanded3(!isExpanded3);
	  };
  
function handleCLickLink(){
	window.scrollTo(0,0);
}

	return (<>
		<div data-plugin-cursor-effect="" className="" style={{}}>

			<div className="body">

				<div role="main" className="main">

					<section
						className="section section-with-shape-divider page-header page-header-modern page-header-lg border-0 my-0 lazyload"
						data-bg-src="assets/img/demos/business-consulting-3/backgrounds/background-5.jpg"
						style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}>
						<div className="container pb-5 my-3">
							<div className="row mb-4">
								<div className="col-md-12 align-self-center p-static order-2 text-center">
									<h1 className="font-weight-bold text-color-dark text-10">Blog</h1>
								</div>
								<div className="col-md-12 align-self-center order-1">
									<ul className="breadcrumb d-block text-center">
										<li><Link to="/" onClick={handleCLickLink} style={{textDecoration:'none',color:'#F04C23'}}>Home</Link></li>
										<li className="active">Blog</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="shape-divider shape-divider-bottom shape-divider-reverse-x" style={{ height: '123px' }}>
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
								x="0px" y="0px" viewBox="0 0 1920 123" preserveAspectRatio="xMinYMin">
								<polygon fill="#F3F3F3" points="0,90 221,60 563,88 931,35 1408,93 1920,41 1920,-1 0,-1 " />
								<polygon fill="#FFFFFF" points="0,75 219,44 563,72 930,19 1408,77 1920,25 1920,-1 0,-1 " />
							</svg>
						</div>
					</section>

					<div className="container pt-3 pb-5 mt-4 mb-5">
						<div className="row">
							<div className="col text-center">
								<h2 className="text-9 text-lg-5 text-xl-9 line-height-3 text-transform-none font-weight-semibold mb-4 mb-lg-3 mb-xl-4 appear-animation animated fadeInUpShorter appear-animation-visible"
									data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250"
									style={{ animationDelay: '250ms' }}>Our Recent News
								</h2>
								<p className="text-3-5 mb-5 appear-animation animated fadeInUpShorter appear-animation-visible"
									data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500"
									style={{ animationDelay: '500ms' }}>Discover the latest happenings in the world of AI and
									development through our recent news blog. Stay informed about cutting-edge technologies,
									industry insights, and innovative solutions that are shaping the future. </p>
							</div>
						</div>
						<div id="john" className="row mb-5 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter"
							data-appear-animation-delay="750"
							style={{ animationDelay: '750ms' }}>
							<div className="col">
								<div className="d-flex flex-wrap bg-light box-shadow-4 custom-link-hover-effects">
									<div className="position-relative col-12 col-md-4 lazyloaded"
										data-bg-src="assets/img/demos/business-consulting-3/blog/blog-12.png" style={{
											backgroundPosition: 'center center',
											backgroundSize: 'cover', minHeight: '320px', backgroundImage: 'url("assets/img/demos/business-consulting-3/blog/blog-12.png")'
										}}>


										<div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
											<div className="custom-crooked-line">
												<img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
													alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
													style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />

											</div>
										</div>
									</div>
									<div className="col-md-8 p-5">
										<div className="d-flex justify-content-between justify-content-md-start mb-4">
											<div>
												<h2 className="font-weight-semibold text-6 line-height-1 mb-0">Artificial
													Intelligence</h2>
												<p className="text-3-5 mb-0">Exploring
													Quantum Computing and AI in the Digital Age.</p>
											</div>
											<div className="social-media bg-color-grey ms-4 me-1 d-none d-md-block" >
											<ul
											
												className="social-media1 social-icons social-icons-clean social-icons-icon-dark social-icons-big m-0 ms-lg-2">
										<div className='social-media2' style={{display:'flex' }}>
												<li className="social-icons-instagram">
													<a href="http://www.instagram.com/" target="_blank" className="text-4"
														title="Instagram" data-cursor-effect-hover="fit"><i
															className="fab fa-instagram"></i></a>
												</li>
												<li className="social-icons-linkedin">
													<a href="http://www.linkedin.com/" target="_blank" className="text-4"
														title="Twitter" data-cursor-effect-hover="fit"><i
															className="fab fa-linkedin-in"></i></a>
												</li>
												<li className="social-icons-facebook">
													<a href="http://www.facebook.com/" target="_blank" className="text-4"
														title="Facebook" data-cursor-effect-hover="fit"><i
															className="fab fa-facebook-f"></i></a>
												</li>
												</div>
											</ul>
											</div>
										</div>


										<div className="{`blog ${expanded ? 'expanded' : ''}`} custom-read-more-style-1 position-relative" style={{ height: isExpanded1 ? 'auto' : '150px', overflowY: 'hidden' }}>
      <p className="text-3-5">In the digital age, the convergence of quantum computing and artificial intelligence (AI) represents a seismic shift in the technological landscape. Quantum computing, with its ability to manipulate quantum bits or qubits, offers a quantum leap in processing power, enabling AI systems to solve complex problems with unprecedented speed and precision.</p>
      {isExpanded1 && (
		<p className="{`additional-content ${expanded ? 'expanded' : ''}`} text-3-5" > This synergy has the potential to revolutionize fields like drug discovery, financial modeling, and climate science, where quantum-enhanced AI algorithms can tackle tasks that were previously infeasible. However, harnessing this potential also comes with challenges, including the need for robust quantum hardware and the development of quantum-friendly AI algorithms. As we navigate this exciting frontier, the fusion of quantum computing and AI promises to reshape industries and unlock the full potential of the digital age, paving the way for groundbreaking advancements and discoveries yet to come.</p>

	  )}
	  
	  {/* <p className="text-3-5"> This synergy has the potential to revolutionize fields like drug discovery, financial modeling, and climate science, where quantum-enhanced AI algorithms can tackle tasks that were previously infeasible. However, harnessing this potential also comes with challenges, including the need for robust quantum hardware and the development of quantum-friendly AI algorithms. As we navigate this exciting frontier, the fusion of quantum computing and AI promises to reshape industries and unlock the full potential of the digital age, paving the way for groundbreaking advancements and discoveries yet to come.</p> */}

      <div className="artificial-intelligence readmore-button-wrapper text-start" style={{ position: 'absolute', bottom: '0px', left: '0px', width: '100%', zIndex: '2' }}>
        <a href="#" className="text-decoration-none" onClick={toggleExpand1}>
          {isExpanded1 ? 'View Less ' : 'View More '}
          <i className={`fas fa-chevron-${isExpanded1 ? 'up' : 'down'} text-2 ms-1`} />
        </a>
		<div ref={contentRef1}></div>
      </div>

      {/*  {isExpanded1 && (
        <div className="readmore-overlay" style={{ background: 'linear-gradient(rgba(2, 0, 36, 0) 0%, rgb(255, 255, 255) 100%)', position: 'absolute', bottom: '0px', left: '0px', width: '100%', height: '100px', zIndex: '1' }}></div>
      )} */} 
    </div>	



										

									</div>
								</div>
							</div>
						</div>
						<div id="robert" className="row mb-5 appear-animation animated fadeInUpShorter appear-animation-visible"
							data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250"
							style={{ animationDelay: '250ms' }}>


							<div className="col">
								<div className="d-flex flex-wrap bg-light box-shadow-4 custom-link-hover-effects">
									<div className="position-relative col-12 col-md-4 lazyloaded"
										data-bg-src="assets/img/demos/business-consulting-3/blog/blog-.2.png"
										style={{
											backgroundPosition: 'center center', backgroundSize: 'cover', minHeight: '320px',
											backgroundImage: 'url("assets/img/demos/business-consulting-3/blog/blog-.2.png")'
										}}>

										<div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
											<div className="custom-crooked-line">
												<img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
													alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
													style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />

											</div>
										</div>
									</div>
									<div className="col-md-8 p-5">
										<div className="d-flex justify-content-between justify-content-md-start mb-4">
											<div>
												<h2 className="font-weight-semibold text-6 line-height-1 mb-0">Development</h2>
												<p className="text-3-5 mb-0">Exploring
													Innovative Solutions and Trends in IT Development.</p>
											</div>
											<div className="social-media bg-color-grey ms-4 me-1 d-none d-md-block" >
											<ul
												className="social-media1 social-icons social-icons-clean social-icons-icon-dark social-icons-big m-0 ms-lg-2">
												<div className='social-media2' style={{display:'flex' }}>
												<li className="social-icons-instagram">
													<a href="http://www.instagram.com/" target="_blank" className="text-4"
														title="Instagram" data-cursor-effect-hover="fit"><i
															className="fab fa-instagram"></i></a>
												</li>
												<li className="social-icons-linkedin">
													<a href="http://www.linkedin.com/" target="_blank" className="text-4"
														title="Twitter" data-cursor-effect-hover="fit"><i
															className="fab fa-linkedin-in"></i></a>
												</li>
												<li className="social-icons-facebook">
													<a href="http://www.facebook.com/" target="_blank" className="text-4"
														title="Facebook" data-cursor-effect-hover="fit"><i
															className="fab fa-facebook-f"></i></a>
												</li>
												</div>
											</ul>
											</div>
										</div>

									
										<div className="custom-read-more-style-1 position-relative" style={{ height: isExpanded2 ? 'auto' : '150px', overflowY: 'hidden' }}>
      <p className="text-3-5">In today's fast-changing tech landscape, exploring IT innovation is vital. Businesses turn to AI, blockchain, and edge computing to stay competitive. AI streamlines data processing, while blockchain ensures trust and security. Edge computing speeds up real-time applications like IoT. These innovations shape a smarter, more connected, and secure digital future.</p>
      <p className="text-3-5">In the evolving IT landscape, DevOps and cloud-native development are rising stars. DevOps fosters collaboration, speeding up software delivery and integration. Cloud-native development builds scalable, resilient apps with cloud tech. These trends boost IT agility, helping businesses adapt swiftly. They shape a future where tech transforms industries and society.</p>

      <div className="development readmore-button-wrapper text-start" style={{ position: 'absolute', bottom: '0px', left: '0px', width: '100%', zIndex: '2' }}>
        <a href="#" className="text-decoration-none" onClick={toggleExpand2}>
          {isExpanded2 ? 'View Less ' : 'View More '}
          <i className={`fas fa-chevron-${isExpanded2 ? 'up' : 'down'} text-2 ms-1`} />
        </a>
      </div>

     {/*  {isExpanded2 && (
        <div className="readmore-overlay" style={{ background: 'linear-gradient(rgba(2, 0, 36, 0) 0%, rgb(255, 255, 255) 100%)', position: 'absolute', bottom: '0px', left: '0px', width: '100%', height: '100px', zIndex: '1' }}></div>
      )} */}
    </div>

	

									</div>
								</div>
							</div>
						</div>

						<div id="jessica" className="row mb-5 appear-animation animated fadeInUpShorter appear-animation-visible"
							data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250"
							style={{ animationDelay: '250ms' }}>


							<div className="col">
								<div className="d-flex flex-wrap bg-light box-shadow-4 custom-link-hover-effects">

									<div className="position-relative col-12 col-md-4 lazyloaded"
										data-bg-src="assets/img/demos/business-consulting-3/blog/blog-.3.png"
										style={{
											backgroundPosition: 'center center', backgroundSize: 'cover', minHeight: '320px',
											backgroundImage: 'url("assets/img/demos/business-consulting-3/blog/blog-.3.png")'
										}}>

										<div className="position-absolute bottom-0 left-0 w-100 py-3 px-4 z-index-3">
											<div className="custom-crooked-line">
												<img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg"
													alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}"
													style={{ width: '154px', opacity: '1' }} className="svg-fill-color-primary" />

											</div>
										</div>
									</div>
									<div className="col-md-8 p-5">
										<div className="d-flex justify-content-between justify-content-md-start mb-4">
											<div>
												<h2 className="font-weight-semibold text-6 line-height-1 mb-0">Automantion</h2>
												<p className="text-3-5 mb-0">Exploring
													Latest in Automation and Technological Innovations.</p>
											</div>
											<div className="social-media bg-color-grey ms-4 me-1 d-none d-md-block"  >
											<ul
												className="social-media1 social-icons social-icons-clean social-icons-icon-dark social-icons-big m-0 ms-lg-2">
											<div className='social-media2' style={{display:'flex'}}>
												<li className="social-icons-instagram">
													<a href="http://www.instagram.com/" target="_blank" className="text-4"
														title="Instagram" data-cursor-effect-hover="fit"><i
															className="fab fa-instagram"></i></a>
												</li>
												<li className="social-icons-linkedin">
													<a href="http://www.linkedin.com/" target="_blank" className="text-4"
														title="Twitter" data-cursor-effect-hover="fit"><i
															className="fab fa-linkedin-in"></i></a>
												</li>
												<li className="social-icons-facebook" >
													<a href="http://www.facebook.com/" target="_blank" className="text-4"
														title="Facebook" data-cursor-effect-hover="fit"><i
															className="fab fa-facebook-f"></i></a>
												</li>
												</div>
											</ul>
											</div>
										</div>


										<div className="custom-read-more-style-1 position-relative" style={{ height: isExpanded3 ? 'auto' : '180px', overflowY: 'hidden' }}>
      <p className="text-3-5">Exploring automation and tech innovations reveals a landscape
												where AI and robotics boost productivity across industries. Breakthroughs like
												5G, IoT, and quantum computing redefine what's possible, enabling real-time
												connectivity and solving complex problems swiftly. This exploration reshapes how
												we work, communicate, and innovate, ushering in a future where technology
												seamlessly integrates into daily life, driving progress.</p>
      <p className="text-3-5">In a fast-changing era, being at the tech forefront is vital for
												both businesses and individuals. Embracing innovations like machine learning,
												autonomous vehicles, and smart cities can boost competitiveness and quality of
												life. Yet, it demands ongoing learning and adaptability as technology evolves.
												As we delve into the latest advancements, we're on a transformative journey with
												the potential to revolutionize industries, elevate our capabilities, and reshape
												society in the years ahead.</p>

      <div className="automation readmore-button-wrapper text-start" style={{ position: 'absolute', bottom: '0px', left: '0px', width: '100%', zIndex: '2' }}>
        <a href="#" className="text-decoration-none" onClick={toggleExpand3}>
          {isExpanded3 ? 'View Less ' : 'View More '}
          <i className={`fas fa-chevron-${isExpanded3 ? 'up' : 'down'} text-2 ms-1`} />
        </a>
      </div>

     {/*  {isExpanded3 && (
        <div className="readmore-overlay" style={{ background: 'linear-gradient(rgba(2, 0, 36, 0) 0%, rgb(255, 255, 255) 100%)', position: 'absolute', bottom: '0px', left: '0px', width: '100%', height: '100px', zIndex: '1' }}></div>
      )} */}
    </div>



									

									</div>
								</div>
							</div>
						</div>

					</div>
				</div>


			</div>


			{/* <!-- Vendor --> */}
			<script data-cfasync="false" src="../../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script>
			<script src="assets/vendor/plugins/js/plugins.min.js"></script>

			{/* <!-- Theme Base, Components and Settings --> */}
			<script src="assets/js/theme.js"></script>

			{/* <!-- Current Page Vendor and Views --> */}
			<script src="assets/js/views/view.contact.js"></script>

			{/* 	<!-- Theme Initialization Files --> */}
			<script src="assets/js/theme.init.js"></script>

			<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAhpYHdYRY2U6V_VfyyNtkPHhywLjDkhfg"></script>



		</div>
	</>)
}

export default Blog