import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom';
import './Contact.css'

const Contact = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');

	return (<>
		<div data-plugin-cursor-effect>
			<div className="body">


				<div role="main" className="main">

					 <section
						className="section section-with-shape-divider page-header page-header-modern page-header-lg border-0 my-0 lazyload"
						data-bg-src="assets/img/demos/business-consulting-3/backgrounds/background-5.jpg"
						style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}>
						<div className="container pb-5 my-3">
							<div className="row mb-4">
								<div className="col-md-12 align-self-center p-static order-2 text-center">
									<h1 className="font-weight-bold text-color-dark text-10">Contact Us</h1>
								</div>
								<div className="col-md-12 align-self-center order-1">
									<ul className="breadcrumb d-block text-center">
										<li><Link to="/" style={{textDecoration:'none',color:'#F04C23'}}>Home</Link></li>
										<li className="active">Contact Us</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="shape-divider shape-divider-bottom shape-divider-reverse-x" style={{ height: '123px' }}>
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
								x="0px" y="0px" viewBox="0 0 1920 123" preserveAspectRatio="xMinYMin">
								<polygon fill="#F3F3F3" points="0,90 221,60 563,88 931,35 1408,93 1920,41 1920,-1 0,-1 " />
								<polygon fill="#FFFFFF" points="0,75 219,44 563,72 930,19 1408,77 1920,25 1920,-1 0,-1 " />
							</svg>
						</div>
					</section> 

					<section className="section section-height-3 bg-light border-0 pt-4 m-0 lazyload"
						data-bg-src="assets/img/demos/business-consulting-3/backgrounds/background-6.jpg"
						style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
						<div className="container py-4">

							<div className="row box-shadow-4 mx-3 mx-xl-0 appear-animation animated fadeInUpShorter appear-animation-visible"
								data-appear-animation="fadeInUpShorter" data-appear-animation-delay="300"
								style={{ animationDelay: '300ms' }}>





<div className="col-lg-6 px-0">
      <div className="bg-light h-100">
        <div className="d-flex flex-column justify-content-center p-5 h-100 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500" style={{ animationDelay: '500ms' }}>
          <div className="pb-5 mb-4 mt-5 mt-lg-0">
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-center pe-lg-4">
              <img width="105" height="105" src="assets/img/demos/business-consulting-3/icons/map-pin.svg" alt="Location" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary mb-4 mb-md-0'}" style={{ width: '105px', opacity: '1' }} className="svg-fill-color-primary mb-4 mb-md-0" />
              <div className="text-center text-md-start ps-md-3">
                <h2 className="font-weight-semibold text-6 mb-1">Dualsysco R&D</h2>
                <p className="text-3-5 mb-0">Beside VIP Function Hall,<br />Aurangabad,<br />Maharashtra</p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center mb-5 mb-lg-0"  >
			<div className='support21' style={{display:'flex', justifyContent:'space-between'}} >
            <div className="col-auto text-center ms-xl-auto mb-4 mb-xl-0" style={{flex:'1.3'}}>
              <h3 className="font-weight-semibold text-color-primary text-3-5 mb-0" style={{fontWeight:'600 !important'}}>SUPPORT</h3>
              <div className="d-flex" style={{alignItems:'center'}}>
                <img width="25" height="25" src="assets/img/demos/business-consulting-3/icons/phone.svg" alt="Phone Icon" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" className="svg-fill-color-primary" style={{ opacity: '1', width: '25px' }} />
                <a href="tel:+919822112848" className="support support-num text-color-dark text-color-hover-primary font-weight-semibold text-decoration-none text-6 ms-2">+91 9822112848</a>
              </div>
            </div>
            <div className="col-auto text-center me-xl-auto"  style={{flex:'1'}}>
              <h3 className="font-weight-semibold text-color-primary text-3-5 mb-0">SALES</h3>
              <div className="d-flex" style={{alignItems:'center'}}>
                <img width="25" height="25" src="assets/img/demos/business-consulting-3/icons/phone.svg" alt="Phone Icon" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" className="svg-fill-color-primary" style={{ opacity: '1', width: '25px' }} />
                <a href="tel:+919607272222" className="support support-num text-color-dark text-color-hover-primary font-weight-semibold text-decoration-none text-6 ms-2">960727222</a>
              </div>
            </div>
			</div>
            <div className="support22 col-auto text-center pt-4 mt-5" >
              <h3 className="font-weight-semibold text-color-primary text-3-5 mb-0">SEND AN EMAIL</h3>
              <div className="support23 d-flex" style={{alignItems:'center'}}>
                <img width="25" height="25" src="assets/img/demos/business-consulting-3/icons/email.svg" alt="Email Icon" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" className="svg-fill-color-primary" style={{ opacity: '1', width: '25px' }} />
                <a href="mailto:info@dualsysco.com" className=" text-color-dark text-color-hover-primary text-decoration-underline font-weight-semibold text-5-5 wb-all ms-2">info@dualsysco.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



								
								
								
								
								
								
								<div className="col-lg-6 px-0">
									<div className="bg-dark h-100">

										<div className="text-center text-md-start p-5 h-100 appear-animation animated fadeInUpShorter appear-animation-visible"
											data-appear-animation="fadeInUpShorter" data-appear-animation-delay="750"
											style={{ animationDelay: '750ms' }}>


											<h2 className="text-color-light font-weight-medium mb-4 mt-5 mt-lg-0">Send Us a Message
												and Learn More About Our Services</h2>
											<p className="text-3-5 font-weight-medium mb-4">Connect with us by sending a message to explore and
												gain deeper insights into our comprehensive range of services and offerings.-
											</p>
											<form
												className="contact-form form-style-4 form-placeholders-light form-errors-light mb-5 mb-lg-0"
												action="https://www.okler.net/previews/porto/9.9.3/php/contact-form.php"
												method="POST">
												<div className="contact-form-success alert alert-success d-none mt-4">
													<strong>Error!</strong> There was an error sending your message.
												</div>

												<div className="contact-form-error alert alert-danger d-none mt-4">
													<strong>Success!</strong> Your message has been sent to us.

													<span className="mail-error-message text-1 d-block"></span>
												</div>

												<div className="row">
													<div className="form-group col">
														<input type="text" value={name} data-msg-required="Please enter your name."
															maxLength="100"
															className="form-control text-3 custom-border-color-grey-1 h-auto py-2"
															name="name" placeholder="* Full Name" required onChange={e => setName(e.target.value)} />
													</div>
												</div>
												<div className="row">
													<div className="form-group col">
														<input type="email" value={email}
															data-msg-required="Please enter your email address."
															data-msg-email="Please enter a valid email address." maxLength="100"
															className="form-control text-3 custom-border-color-grey-1 h-auto py-2"
															name="email" placeholder="* Email Address" required onChange={e => setEmail(e.target.value)} />
													</div>
												</div>
												<div className="row mb-4">
													<div className="form-group col">
														<textarea maxLength="5000"
															data-msg-required="Please enter your message." rows="8"
															className="form-control text-3 custom-border-color-grey-1 h-auto py-2"
															name="message" placeholder="* Message" required></textarea>
													</div>
												</div>
												<div className="row">
													<div className="form-group col">
														<button type="submit"
															className="btn btn-primary custom-btn-style-1 font-weight-semibold btn-px-4 btn-py-2 text-3-5"
															data-loading-text="Loading..." data-cursor-effect-hover="plus"
															data-cursor-effect-hover-color="light">
															<span>Send Message</span>
														</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>




				</div>

				{/* <!-- Vendor --> */}
				<script data-cfasync="false" src="assets/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script>
				<script src="assets/vendor/plugins/js/plugins.min.js"></script>

				{/* 	<!-- Theme Base, Components and Settings --> */}
				<script src="assets/js/theme.js"></script>

				{/* 	<!-- Current Page Vendor and Views --> */}
				<script src="assets/js/views/view.contact.js"></script>

				{/* <!-- Theme Initialization Files --> */}
				<script src="assets/js/theme.init.js"></script>

				<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAhpYHdYRY2U6V_VfyyNtkPHhywLjDkhfg"></script>



			</div>

		</div>

	</>)
}

export default Contact