

import Footer from "./components/Footer";
import Header from "./components/Header";

import About from "./pages/About";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Services from "./pages/Services";
import { Routes,Route } from "react-router-dom";


function App() {
  return ( <>
    <div className="App">
    <Header />

      <Routes>
<Route path="/" element={<Home/>}/>
<Route path="about" element={<About/>}/>
<Route path="services" element={<Services/>}/>
<Route path="portfolio" element={<Portfolio/>}/>
<Route path="blog" element={<Blog/>}/>
<Route path="contact" element={<Contact/>}/>

      </Routes>
      <Footer/>

     {/* <Home/>
     <About/>
     <Services/>
     <Portfolio/>
     <Blog/>
     <Contact/> */}
    </div>
    </> );
}

export default App;
