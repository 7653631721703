import React, { useState } from 'react'

const Tempo9 = () => {

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');

	/* function handleChange(event){
	console.log(event.target.value);
	}
	 */
	return (
		<>
			<section className="section section-with-shape-divider position-relative bg-dark border-0 m-0">
				<div className="shape-divider shape-divider-reverse-x z-index-3" style={{ height: '102px' }}>
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 102" preserveAspectRatio="xMinYMin">
						<polygon fill="#F3F3F3" points="0,65 220,35 562,63 931,10 1410,68 1920,16 1920,103 0,103 "></polygon>
						<polygon fill="#EDEDED" points="0,82 219,51 562,78 931,26 1409,83 1920,32 1920,103 0,103 "></polygon>
					</svg>
				</div>
				<div className="position-absolute top-0 right-0 overlay overlay-show overlay-color-primary overlay-op-9 h-100 d-none d-md-block lazyloaded" data-bg-src="assets/img/demos/business-consulting-3/backgrounds/background-2.jpg"
					style={{
						width: '32%', backgroundSize: 'cover', backgroundPosition: 'center center',
						backgroundImage: 'url("assets/img/demos/business-consulting-3/backgrounds/background-2.jpg")'
					}}></div>
				<div className="container">
					<div className="row align-items-center pt-5 pb-xl-5 mt-5">
						<div className="col-md-7 col-lg-8 py-4 my-2 ms-md-3 ms-lg-0">
							<h2 className="custom-highlight-text-1 d-inline-block line-height-5 text-4 positive-ls-3 font-weight-medium text-color-primary mb-2 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250" style={{ animationDelay: '250ms' }}>GET IN TOUCH
							</h2>
							<h3 className="text-9 line-height-3 text-transform-none font-weight-medium text-color-light ls-0 mb-3 pb-1 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500" style={{ animationDelay: '500ms' }}>Send Us a
								Message and Learn More About Our Services</h3>
							<p className="text-3-5 pb-3 mb-4 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="750" style={{ animationDelay: '750ms' }}>Connect with us by sending a message to explore and
								gain deeper insights into our comprehensive range of services and offerings. </p>
							<form className="contact-form form-style-4 form-placeholders-light form-errors-light appear-animation animated fadeInUpShorter appear-animation-visible"
								data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000"
								action="https://www.okler.net/previews/porto/9.9.3/php/contact-form.php"
								method="POST" noValidate="novalidate" style={{ animationDelay: '1000ms' }}>

								<div className="contact-form-success alert alert-success d-none mt-4">
									<strong>Error!</strong> There was an error sending your message.

								</div>

								<div className="contact-form-error alert alert-danger d-none mt-4">
									<strong>Success!</strong> Your message has been sent to us.

									<span className="mail-error-message text-1 d-block"></span>
								</div>

								<div className="row">
									<div className="form-group col">
										<input type="text" value={name} data-msg-required="Please enter your name." maxLength="100"
										onChange={e => setName(e.target.value)} className="form-control text-3 custom-border-color-grey-1 h-auto py-2 error"
											name="name" placeholder="* Full Name"  required="" aria-invalid="true"  />

										 {/* <label id="name-error" className="error" htmlFor="name" style={{}}>Please enter your name.</label>  */}
									</div>
								</div>
								<div className="row">
									<div className="form-group col">
										<input type="email" value={email} data-msg-required="Please enter your email address."
											data-msg-email="Please enter a valid email address."
											maxLength="100" className="form-control text-3 custom-border-color-grey-1 h-auto py-2 error"
											name="email"
											onChange={e => setEmail(e.target.value)}
											placeholder="* Email Address" required aria-invalid="true" />

										 {/* <label id="email-error" className="error" htmlFor="email" style={{}}>Please enter your email address.</label>  */}
									</div>

								</div>
								<div className="row mb-4">


									<div className="form-group col">
										<textarea maxLength="5000" data-msg-required="Please enter your message." rows="8" className="form-control text-3 custom-border-color-grey-1 h-auto py-2" name="message" placeholder="* Message" required>

										</textarea>
									</div>

								</div>
								<div className="row">
									<div className="form-group col appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1250"
										style={{ animationDelay: '1250ms' }}>
										<button type="submit" className="btn btn-primary custom-btn-style-1 font-weight-semibold btn-px-4 btn-py-2 text-3-5" data-loading-text="Loading..." data-cursor-effect-hover="plus" data-cursor-effect-hover-color="light">
											<span>Send Message</span>
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>

		</>
	)
}

export default Tempo9