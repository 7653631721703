import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
	function handleCLickLink (){
		window.scrollTo(0,0);
	}
	return (<>

		<footer id="footer" className="border-top-0 m-0 lazyload"
			data-bg-src="assets/img/demos/business-consulting-3/backgrounds/background-4.jpg"
			style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', justifyContent: 'space-evenly' }}>
			<div className="container pt-3" style={{ justifyContent: 'space-evenly' }}>
				<div className="row justify-content-between align-items-center py-5 mb-3">
					<div className="col-auto mb-4 mb-lg-0">
						<h2 className="font-weight-semibold text-color-light text-10 ls-0 mb-0">Dualsysco Research &
							Development Group</h2>
					</div>
					<div className="col-auto">
						<Link to="contact" onClick={handleCLickLink}
							className="btn btn-primary custom-btn-style-1 font-weight-medium btn-px-4 btn-py-2 text-4"
							data-cursor-effect-hover="plus" data-cursor-effect-hover-color="light">
							<span className="text-color-light">Contact Us</span>
						</Link>
					</div>
				</div>
				<hr className="bg-color-light opacity-1 my-0" style={{ justifyContent: 'space-evenly' }} />
				<div className="row pt-3 mt-5" style={{ justifyContent: 'space-evenly' }}>
					<div className="col-lg-3 mb-4 mb-lg-0">
						<Link to="/" className="text-decoration-none" onClick={handleCLickLink} > 
							<img src="assets/img/demos/business-consulting-3/Dualsysco-Logo-White.png" className="img-fluid mb-4"
								width="150" height="55" alt="" />
						</Link>
						<p className="text-3-5">Shaping Tomorrow's Innovations Today. Crafting a Future of Excellence
							through Dedication and Expertise. </p>
						<ul
							className="social-icons social-icons-clean social-icons-clean-with-border social-icons-medium social-icons-icon-light">
							<li className="social-icons-facebook"><a href="http://www.facebook.com/" target="_blank"
								title="Facebook" data-cursor-effect-hover="fit"><i
									className="fab fa-facebook-f"></i></a></li>
							<li className="social-icons-twitter mx-2"><a href="http://www.instagram.com/" target="_blank"
								title="instagram" data-cursor-effect-hover="fit"><i
									className="fab fa-instagram"></i></a></li>
							<li className="social-icons-linkedin"><a href="https://www.linkedin.com/company/dualsysco-research-and-development/" target="_blank"
								title="Linkedin" data-cursor-effect-hover="fit"><i
									className="fab fa-linkedin-in"></i></a></li>
						</ul>
					</div>
					<div className="col-lg-3 mb-4 mb-lg-0">
						<ul className="list list-icons list-icons-lg">
							<li className="d-flex px-0 mb-1">
								<img width="25" src="assets/img/demos/business-consulting-3/icons/phone.svg" alt="Phone Icon"
									data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light'}"
									className="svg-fill-color-light" style={{ opacity: '1', width: '25px', filter: 'invert(1)' }} />

							<a href="tel:9822112848"  className="text-color-light font-weight-semibold text-3-4 ms-2" style={{ textDecoration: 'none' }}>+91
									9822112848</a>
							</li>
							<li className="d-flex px-0 my-3">
								<img width="25" src="assets/img/demos/business-consulting-3/icons/email.svg"
									alt="Email Icon" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light'}"
									className="svg-fill-color-light" style={{ opacity: '1', width: '25px', filter: 'invert(1)' }} />

								<a href="https://www.okler.net/cdn-cgi/l/email-protection#bdcdd2cfc9d2fdcdd2cfc9d2c9d5d8d0d893ded2d0"
									className="text-color-light font-weight-semibold text-3-4 ms-2" style={{ textDecoration: 'none' }}><span
										className="__cf_email__"  style={{color:'#FFFFFF'}} >info@dualsysco.com</span></a>
							</li>
							<li className="d-flex font-weight-semibold text-color-light px-0 mb-1">
								<img width="25" src="assets/img/demos/business-consulting-3/icons/map-pin.svg" alt="Location"
									data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light me-2'}"
									className="svg-fill-color-light me-2" style={{ opacity: '1', width: '25px', filter: 'invert(1)' }} />

								Beside VIP Function Hall, Central Naka Rd,
								Rahemania Colony, Kiradpura, Aurangabad, Maharashtra
								431001
							</li>
						</ul>
					</div>

					<div className="col-lg-2" >
						<h4 className="font-weight-bold text-5">Useful Links</h4>
						<ul className="list list-icons list-icons-sm" >
							<li>
								<i className="fas fa-angle-right text-color-default" ></i>
								<Link to="contact" className="link-hover-style-1 ms-1" onClick={handleCLickLink} style={{ textDecoration: 'none' }}>
									Contact Us</Link>
							</li>
							<li>
								<i className="fas fa-angle-right text-color-default"></i>
								<Link to="services" className="link-hover-style-1 ms-1" onClick={handleCLickLink} style={{ textDecoration: 'none' }}> Our
									Services</Link>
							</li>
							<li>
								<i className="fas fa-angle-right text-color-default"></i>
								<Link to="#" className="link-hover-style-1 ms-1" onClick={handleCLickLink} style={{ textDecoration: 'none' }}> Payment Methods</Link>
							</li>
							<li>
								<i className="fas fa-angle-right text-color-default"></i>
								<Link to="#" className="link-hover-style-1 ms-1"onClick={handleCLickLink} style={{ textDecoration: 'none' }}> Services Guide</Link>
							</li>
							<li>
								<i className="fas fa-angle-right text-color-default"></i>
								<Link to="#" onClick={handleCLickLink} className="link-hover-style-1 ms-1"style={{ textDecoration: 'none' }}> FAQs</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="footer-copyright container bg-transparent">
				<div className="row pb-5">
					<div className="col-lg-12 text-center m-0">
						<hr className="bg-color-light opacity-1 mt-5 mb-4" />
						<p className="text-3-4">Dualsysco Research & Development. © 2014. All Rights Reserved</p>
					</div>
				</div>
			</div>
		</footer>
	</>)
}

export default Footer