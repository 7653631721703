import React from 'react'
import { Link } from 'react-router-dom'
import './Tempo2css.css';
import OwlMobile from './OwlMobile';

const Tempo2 = () => {

	function handleCLickLink() {
		window.scrollTo(0, 0);
	}
	return (


		<>
			<div className="taleeb container" style={{ marginTop: '-310px' }}>
				<div className="row">
					<div className="col-xl-7 ms-xl-auto">
					<OwlMobile/>
						<div className="mobile-off owl-carousel owl-theme custom-carousel-box-shadow-1 custom-dots-style-1 mb-0 owl-loaded owl-drag owl-carousel-init"
						 data-plugin-options="{'responsive': {'576': {'items': 1}, '768': {'items': 2}, '992': {'items': 2}, '1200': {'items': 2}}, 'margin': 20, 'loop': false, 'nav': false, 'autoplay': true, 'autoplayTimeout': 5000, 'dots': true}" 
						 responsive={{
							0:{items:1},
							768:{items:1},
							992:{items:1},
							1200:{items:1}
						  }}

						 style={{ height: 'auto' }}>




							<div className="owl-stage-outer"><div className="owl-stage" style={{ transform: 'translate3d(0px, 0px, 0px)', transition: 'all 0s ease 0s', width: '661px' }}>
								<div className="owl-item active" style={{ width: '310.5px', marginRight: '20px' }}><div>
								<Link to="/services" onClick={() => window.scrollTo(0, 0)} className="custom-link-hover-effects text-decoration-none" data-cursor-effect-hover="plus">
									<div className="card border-0">
										<div className="card-body text-center py-5">
											<img width="25vh" height="25vh" src="assets/img/demos/business-consulting-3/icons/AI icon.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" style={{ width: '25vh', height: '75px', opacity: '1' }} className="svg-fill-color-primary" />
											<div className="custom-crooked-line">
												<img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" style={{ width: '154px', height: '26px', opacity: '1', }} className="svg-fill-color-primary" />
											</div>
											<h2 className="text-5 font-weight-semibold mb-1">Artificial Intelligence </h2>
											<p className="pb-1 mb-2"> Boost business with artificial intelligence. </p>
											<span className="custom-view-more d-inline-flex font-weight-medium text-color-primary">
												View More
												<img width="27" height="27" src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary ms-2'}" style={{ width: '27px', opacity: '1' }} className="svg-fill-color-primary ms-2" />
											</span>
										</div>
									</div>
								</Link>
							</div></div>
							
							<div className="owl-item active" style={{ width: '310.5px', marginRight: '20px' }}><div>
								<Link to="/services" onClick={() => window.scrollTo(0, 0)} className="custom-link-hover-effects text-decoration-none" data-cursor-effect-hover="plus">
									<div className="card border-0">
										<div className="card-body text-center py-5">
											<img width="25vh" height="25vh" src="assets/img/demos/business-consulting-3/icons/development.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" style={{ width: '25vh', height: '75px', opacity: '1' }} className="svg-fill-color-primary" />
											<div className="custom-crooked-line">
												<img width="154" height="26" src="assets/img/demos/business-consulting-3/icons/infinite-crooked-line.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" style={{ width: '154px', height: '26px', opacity: '1' }} className="svg-fill-color-primary" />
											</div>
											<h2 className="text-5 font-weight-semibold mb-1">Development</h2>
											<p className="pb-1 mb-2">Creating innovative software for evolving business
												needs.</p>
											<span className="custom-view-more d-inline-flex font-weight-medium text-color-primary">
												View More
												<img width="27" height="27" src="assets/img/demos/business-consulting-3/icons/arrow-right.svg" alt="" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary ms-2'}" style={{ width: '27px', opacity: '1' }} className="svg-fill-color-primary ms-2" />
											</span>
										</div>
									</div>
								</Link>
							</div></div>
							
							</div></div><div className="owl-nav disabled"><button type="button" role="presentation" className="owl-prev"></button><button type="button" role="presentation" className="owl-next"></button></div><div className="owl-dots disabled"><button role="button" className="owl-dot active">
								<span></span></button></div></div>
					</div>
				</div>

				<div id="aboutus" className="row align-items-xl-center pt-4 mt-5">
					<div className="col-md-10 col-lg-6 mb-5 mb-lg-0">
						<div className="row row-gutter-sm">
							<div className="col-6">
								<img src="assets/img/demos/business-consulting-3/generic/generic-11.jpg" className="img-fluid box-shadow-5" alt="" />

							</div>
							<div className="col-6">
								<img src="assets/img/demos/business-consulting-3/generic/generic-2.jpg" className="img-fluid box-shadow-5 mb-4" alt="" />
								<img src="assets/img/demos/business-consulting-3/generic/generic-3.jpg" className="img-fluid box-shadow-5" alt="" />
							</div>

						</div>

					</div>
					<div className="col-lg-6 ps-lg-4 ps-xl-5">
						<h2 className="custom-highlight-text-1 d-inline-block line-height-5 text-4 positive-ls-3 font-weight-medium text-color-primary mb-2 appear-animation animated fadeInUpShorter appear-animation-visible"
							data-appear-animation="fadeInUpShorter" style={{ animationDelay: '100ms' }}>ABOUT US</h2>
						<h3 className="text-9 text-lg-5 text-xl-9 line-height-3 text-transform-none font-weight-semibold mb-4 mb-lg-3 mb-xl-4 appear-animation animated fadeInUpShorter appear-animation-visible"
							data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250"
							style={{ animationDelay: '250ms' }}>Welcome to
							Dualsysco<br /> Research &amp; Development</h3>

						<p className="text-3-5 pb-1 mb-4 mb-lg-2 mb-xl-4 appear-animation animated fadeInUpShorter appear-animation-visible"
							data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500"
							style={{ animationDelay: '500ms' }}>Established in
							2014
							, Dualsysco is an innovative IT company
							specializing in cutting
							-edge research and development
							. Our
							expertise extends to guiding startups from conception to
							deployment, excelling in software, app development, AI
							architecture, CRM, ERP, and Mobile Application development
							.
							As pioneers in AI and Machine Learning integration, we empower
							businesses with unparalleled efficiency and intelligence
							.
							Trusted by both Private and Government sectors, we offer
							comprehensive support, from refining ideas to post
							-production
							services, ensuring exceptional solutions and customer satisfaction
							.
							Committed to staying at the forefront of the rapidly evolving IT
							industry, we continuously invest in research and development,
							including IoT and Big Data technologies
							.
							Partner with Dualsysco to shape the future with innovative
							technological solutions
							. </p>




						<div className=" media row align-items-center pb-2 mb-4 mb-lg-1 mb-xl-4 appear-animation animated fadeInUpShorter appear-animation-visible"
							data-appear-animation="fadeInUpShorter" data-appear-animation-delay="750"
							style={{ animationDelay: '750ms' }}>
							<div className="media1 col-5" > 
								<div className="media3 d-flex">
									<img width="63" height="63" src="assets/img/demos/business-consulting-3/icons/label.svg" alt="" data-icon=""
										data-plugin-options="{'onlySVG': true, 'extraClass': 'd-lg-none d-xl-block'}"
										className="d-lg-none d-xl-block" style={{
											opacity: '1', width: '63px'}}/>
									<span className="font1 text-3 font-weight-bold text-color-dark pt-2 ms-2"

									>
										<strong className="font d-block font-weight-bold text-9 mb-2"

										>

											240+</strong>
										Satisfied Clients
									</span>
								</div>
							</div>

							<div className="media4 col-7">
								<blockquote className="custom-blockquote-style-1 m-0 pt-1 pb-2">
									<p className="mb-0"> Join us on the journey to harness the power of AI and development
										to achieve your business goals.</p>
								</blockquote>
							</div>
						</div>






						<div className="appear-animation animated fadeInUpShorter appear-animation-visible"
							data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000"
							style={{ animationDelay: '1000ms' }}>
							<Link to="/contact" onClick={handleCLickLink} className="btn btn-primary custom-btn-style-1 font-weight-semibold btn-px-4 btn-py-2 text-3-5"
								data-cursor-effect-hover="plus" data-cursor-effect-hover-color="light"><span>Get a
									Quote</span></Link>
						</div>

					</div>
				</div>


			</div>

		</>
	)
}

export default Tempo2