import React from 'react'
import { Link } from 'react-router-dom'

const Tempo = () => {
  return (
    <>
      <section className="section section-height-3 section-with-shape-divider position-relative border-0 m-0"
        data-plugin-parallax=""
        data-plugin-options='{"speed": 1.5, "parallaxHeight": "120%"}'
        style={{ position: 'relative', overflow: 'hidden' }}
        data-image-src="assets/img/demos/business-consulting-3/backgrounds/background-1.jpg"

      // style={{backgroundImage:'url("assets/img/demos/business-consulting-3/backgrounds/background-1.jpg")'}}
      >
        <div className="parallax-background" style={{
          backgroundImage: 'url("assets/img/demos/business-consulting-3/backgrounds/background-1.jpg")',
          backgroundSize: 'cover',
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '120%',
          transform: 'translate3d(0px, -70.3482px, 0px)',
          backgroundPositionX: '50%'
        }}>
        </div>
        <img
          src="assets/img/demos/business-consulting-3/backgrounds/456.png"
          className="img-fluid position-absolute top-0 right-0 d-none d-md-block appear-animation animated fadeInUpShorter appear-animation-visible"
          data-appear-animation="fadeInUpShorter"
          data-appear-animation-delay="1100"
          alt=""
          style={{
            marginTop: '5vh',
            marginRight: '0vh',
            height: '80vh',
            width: '80vh',
            borderRadius: '45%',
            animationDelay: '1100ms'
          }}
        />
        <div className="container pb-5 pb-xl-0 mt-md-3 mb-5">
          <div className="row">
            <div className="col-md-7 col-lg-12">
              <div className="appear-animation animated fadeInUpShorter appear-animation-visible"
                data-appear-animation="fadeInUpShorter"
                data-appear-animation-delay="0"
                style={{ animationDelay: '0ms' }}>
                <h1 className="custom-text-background custom-big-font-size-2 text-14 font-weight-bold float-xl-start clearfix line-height-1 custom-ws-mobile-wrap ws-nowrap pb-2 mb-3 mb-xl-5"
                  style={{ backgroundImage: 'url(assets/img/demos/business-consulting-3/backgrounds/text-background.jpg)' }}>
                  Welcome To Dualsysco R&amp;D
                </h1>
              </div>

            </div>
          </div>

          <div className="row pb-5 mb-5">
            <div className="col-md-7 col-xl-5 pb-5 pb-xl-0 mb-5">
              <strong className="d-block font-weight-semibold text-color-dark text-5-5 line-height-4 mb-3 pb-1 appear-animation animated fadeInUpShorter appear-animation-visible"
                data-appear-animation="fadeInUpShorter"
                data-appear-animation-delay="500"
                style={{ animationDelay: '500ms' }}>
                <span className="custom-highlight-text-1 font-weight-bold"></span></strong>
              <p className="text-3-5 mb-4 appear-animation animated fadeInUpShorter appear-animation-visible"
                data-appear-animation="fadeInUpShorter" data-appear-animation-delay="750"
                style={{ animationDelay: '750ms' }}>Established in 2014, Dualsysco is an innovative<br /> IT
                leader specializing in AI-driven software, app,<br /> and AI development for startups,
                serving Private<br /> and Government sectors with excellence. With a commitment to
                innovation, we invest in IoT and<br /> Big Data tech to shape the future with
                cutting<br />-edge solutions. </p>
              <a href="#aboutus"
                data-hash=""
                data-hash-offset="0"
                data-hash-offset-lg="100"
                className="d-inline-flex align-items-center text-decoration-none appear-animation animated fadeInUpShorter appear-animation-visible"
                data-appear-animation="fadeInUpShorter"
                data-appear-animation-delay="1000">
                <img
                  width="30"
                  height="30"
                  src="assets/img/demos/business-consulting-3/icons/mouse.svg"
                  alt=""
                  data-icon=""
                  data-plugin-options='{"onlySVG": true, "extraClass": "svg-fill-color-primary me-2"}'
                  className="svg-fill-color-primary me-2"
                  style={{ opacity: '1', width: '30px' }}
                />
                Scroll Down
              </a>

            </div>
          </div>
        </div>
        <div className="shape-divider shape-divider-bottom" style={{ height: '212px' }}>

          <div className="shape-divider-horizontal-animation shape-divider-horizontal-animation-to-left start">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 212" preserveAspectRatio="none">
              <polygon fill="#F3F3F3" points="0,75 479,161 1357,28 1701,56 1920,26 1920,213 0,212 "></polygon>
              <polygon fill="#FFFFFF" points="0,91 481,177 1358,44 1702,72 1920,42 1920,212 0,212 "></polygon>
            </svg>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 212" preserveAspectRatio="none">
              <polygon fill="#F3F3F3" points="0,75 479,161 1357,28 1701,56 1920,26 1920,213 0,212 "></polygon>
              <polygon fill="#FFFFFF" points="0,91 481,177 1358,44 1702,72 1920,42 1920,212 0,212 "></polygon>
            </svg><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 212" preserveAspectRatio="none">
              <polygon fill="#F3F3F3" points="0,75 479,161 1357,28 1701,56 1920,26 1920,213 0,212 "></polygon>
              <polygon fill="#FFFFFF" points="0,91 481,177 1358,44 1702,72 1920,42 1920,212 0,212 "></polygon>
            </svg></div>

        </div>



      </section>
    </>
  )
}

export default Tempo